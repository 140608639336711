import { Box, Stack, Typography } from '@mui/material';
import Link from 'next/link';

import { Button } from '@fllite-fe/shared/src/components/Button';
import { scrollToTarget } from '@fllite-fe/shared/src/utils/scrollToTarget';

import { BannerContainer, StyledImage } from './DashboardBanner.style';

export type DashboardBannerProps = {
	bannerInfo: {
		textContent: {
			title: string;
			description: string;
		};
		imageContent: {
			url: string;
			alt: string;
		};
		buttonContent: {
			linkTo: string;
			buttonText: string;
			medium?: boolean;
			secondary?: boolean;
			scrollToTargetKey?: string;
			testId?: string;
		};
	};
};

export const DashboardBanner = ({ bannerInfo }: DashboardBannerProps) => {
	const { linkTo, buttonText, scrollToTargetKey, testId, ...buttonStyle } =
		bannerInfo.buttonContent;
	const { description, title } = bannerInfo.textContent;
	const { alt, url } = bannerInfo.imageContent;

	const handleScrollTo = (e) => {
		if (!scrollTo) return;
		e.preventDefault();
		scrollToTarget(scrollToTargetKey);
	};

	return (
		<BannerContainer>
			<Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 2 }}>
				<Box py="24px" px="16px" width="100%" order={{ xs: 1, md: 0 }}>
					<Typography color="primary" fontSize={20} fontWeight={600} mb={1}>
						{title}
					</Typography>
					<Typography color="#353535" mb={3} fontSize={16}>
						{description}
					</Typography>
					<Link href={linkTo} passHref legacyBehavior>
						<Button
							{...buttonStyle}
							onClick={scrollToTargetKey ? handleScrollTo : null}
							{...(testId ? { 'data-testid': testId } : {})}
						>
							{buttonText}
						</Button>
					</Link>
				</Box>
				<Box
					alignSelf={{ xs: 'center', md: 'auto' }}
					borderRadius="8px"
					flexShrink={0}
					height={{ xs: '120px', md: 'auto' }}
					width={{ xs: '100%', sm: '330px', md: '40%', lg: '500px' }}
					maxWidth="100%"
					minHeight={{ xs: 'unset', md: '192px' }}
					mt={{ xs: '0 !important' }}
					order={{ xs: 0, md: 1 }}
					overflow="hidden"
					position="relative"
				>
					<StyledImage src={url} alt={alt} fill objectFit="cover" />
				</Box>
			</Stack>
		</BannerContainer>
	);
};
