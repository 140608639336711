import { FC, useEffect } from 'react';

import styled from '@emotion/styled';
import { Tooltip, useMediaQuery } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { NumberInput } from '../../../form-fields/NumberInput';
import RadioButtonGroup from '../../../mui-form-fields/RadioButtonGroup';
import { muiTheme } from '../../../muiTheme';
import { Icon } from '../../Icon';
import { Text } from '../../Text';
import {
	Column,
	FirstLineBox,
	InfoIconWrapper,
	InnerBox,
	InnerBoxInput,
	PassengerBox,
	PersonIconWrapper,
	SecondLineBox,
	StyledText,
	SubmitButton,
	TitleBox,
} from '../RaFForm.styles';
import { DisplayForm } from './DisplayForm';
import { MultiLeg } from './MultiLeg';
import { OneWay } from './OneWay';
import { RoundTrip } from './RoundTrip';

const FormGroupContent = styled('div')`
	width: 100%;
`;

interface TripFormGroupProps {
	flightTypesOptions: any[];
	isLoading?: boolean;
}

export const TripFormGroup: FC<TripFormGroupProps> = ({ flightTypesOptions, isLoading }) => {
	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('md'));
	const { watch, control, setValue } = useFormContext();

	const watchAllFields = watch();
	const selectedFlightType = watchAllFields?.trip?.flightType;
	const watchLegs = watchAllFields.trip?.legs;

	const oneWayDeparture = watchLegs?.oneWay?.departure;
	const oneWayDestination = watchLegs?.oneWay?.destination;
	const oneWayDateFrom = watchLegs?.oneWay?.dateFrom;

	const roundTripDeparture = watchLegs?.roundTrip?.departure;
	const roundTripDestination = watchLegs?.roundTrip?.destination;
	const roundTripDateFrom = watchLegs?.roundTrip?.dateFrom;

	useEffect(() => {
		if (selectedFlightType?.constantKey === 'FLIGHTTYPE_ONE_WAY') {
			roundTripDeparture && setValue('trip.legs.oneWay.departure', roundTripDeparture);
			roundTripDestination && setValue('trip.legs.oneWay.destination', roundTripDestination);
			roundTripDateFrom && setValue('trip.legs.oneWay.dateFrom', roundTripDateFrom);
		} else if (selectedFlightType?.constantKey === 'FLIGHTTYPE_ROUND_TRIP') {
			oneWayDeparture && setValue('trip.legs.roundTrip.departure', oneWayDeparture);
			oneWayDestination && setValue('trip.legs.roundTrip.destination', oneWayDestination);
			oneWayDateFrom && setValue('trip.legs.roundTrip.dateFrom', oneWayDateFrom);
		} else {
			// in future maybe we will need some logic for multi leg, but I hope that we will not need it
		}
	}, [selectedFlightType?.constantKey]);

	return (
		<FormGroupContent>
			<Column>
				<FirstLineBox>
					<RadioButtonGroup name="trip.flightType" options={flightTypesOptions} row returnObject />
				</FirstLineBox>

				<SecondLineBox>
					<DisplayForm isVisible={selectedFlightType?.constantKey === 'FLIGHTTYPE_ONE_WAY'}>
						<OneWay />
					</DisplayForm>
					<DisplayForm
						isVisible={
							selectedFlightType?.constantKey === 'FLIGHTTYPE_ROUND_TRIP' ||
							!selectedFlightType?.constantKey
						}
					>
						<RoundTrip />
					</DisplayForm>
					<DisplayForm isVisible={selectedFlightType?.constantKey === 'FLIGHTTYPE_MULTI_LEG'}>
						<MultiLeg />
					</DisplayForm>
					<PassengerBox>
						<PersonIconWrapper>
							<Icon type="person-light-blue" />
						</PersonIconWrapper>
						<InnerBox>
							<InnerBoxInput>
								<Controller
									control={control}
									name="trip.seats"
									render={({ field: { onChange, value, name }, fieldState: { error } }) => {
										return (
											<NumberInput
												name={name}
												value={value}
												error={error}
												onChange={onChange}
												placeholder="0"
												height={0.5}
												marginTopError={26}
												rafFormStyle
												min={1}
											/>
										);
									}}
								/>
							</InnerBoxInput>
							<TitleBox>
								<StyledText subtext grayDark>
									Passengers
								</StyledText>

								<Tooltip
									arrow
									title={
										<Text small white>
											According to FAA rules, infants and toddlers under the age of 2 years can
											travel on the lap of an adult for free
										</Text>
									}
									enterTouchDelay={isDesktop ? 700 : 0}
								>
									<InfoIconWrapper>
										<Icon type="info-light-blue" />
									</InfoIconWrapper>
								</Tooltip>
							</TitleBox>
						</InnerBox>
					</PassengerBox>
				</SecondLineBox>

				<SubmitButton type="submit" secondary isLoading={isLoading}>
					<Icon type="chevron-right-white" />
				</SubmitButton>
			</Column>
		</FormGroupContent>
	);
};
