import { FC } from 'react';

import { H3 } from '@fllite-fe/shared/src/components/Text';

import { HeaderContainer, Subtitle, Wrapper } from './Elements.styles';

interface DashboardHeaderProps {
	labelId: string;
	sublabelId: string;
	marginBottom?: string;
	maxWidth?: string;
}

export const DashboardHeader: FC<DashboardHeaderProps> = ({
	labelId,
	sublabelId,
	marginBottom = '25px',
	maxWidth = 'unset',
}) => (
	<HeaderContainer marginBottom={marginBottom}>
		<Wrapper maxWidth={maxWidth}>
			<H3 primaryDarkest centered>
				{labelId}
			</H3>
			<Subtitle>{sublabelId}</Subtitle>
		</Wrapper>
	</HeaderContainer>
);
