import { styled } from '@mui/material';

export const LoadingContent = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent url('/assets/loader-airplane.webp') no-repeat top center;
	padding-top: 90px;
	margin-top: 50px;
	margin-bottom: 50px;
`;
