import styled from '@emotion/styled';
import { pick } from 'dot-object';
import { useFieldArray, useFormContext } from 'react-hook-form';

import DateTimePicker from '../../../form-fields/DateTimePicker';
import { getCurrentMoment } from '../../../utils/datetime';
import { Button } from '../../Button';
import { Icon } from '../../Icon';
import {
	DatePickerColumn,
	FirstCol,
	FromToBox,
	IconBox,
	PlaceAndTimeBox,
	SecondCol,
} from '../RaFForm.styles';
import { AirportSelectMapBoxSearchBox } from './AirportSelectMapBoxSearchBox';

export const StyledButton = styled(Button)`
	width: 100%;
	margin-top: 15px;
	${({ theme }) => theme.breakpoints.up('md')} {
		width: 40px;
		height: 40px;
		min-width: 40px;
		min-height: 40px;
		align-self: center;
		margin-left: 4px;
		margin-top: 0;
	}
`;

const MAX_LEGS = 6;
const MIN_TO_REMOVE = 2;

const Leg = styled('div')<{ isLast?: boolean; isFirst?: boolean }>`
	margin-bottom: ${({ isLast }) => (isLast ? '10px' : '45px')};
	width: 100%;

	${({ theme }) => theme.breakpoints.down('md')} {
		margin-top: ${({ isFirst }) => (isFirst ? 0 : '85px')};
	}
`;

const AddButtonContainer = styled('div')`
	padding-top: 24px;
`;

const AddButton = styled(Button)`
	margin-left: 10px;
	font-size: 16px;
`;

const Box = styled('div')`
	width: 100%;
	margin-right: -5px;
`;

const StyledIcon = styled(Icon)`
	margin-right: 15px;
`;

export const Row = styled('div')`
	position: relative;
	width: 100%;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin: 0 -5px;
		display: flex;
	}
`;

const ButtonRemove = styled(StyledButton)`
	position: absolute;
	right: -138px;

	${({ theme }) => theme.breakpoints.down('md')} {
		top: -11px;
		right: -65%;
	}

	${({ theme }) => theme.breakpoints.down('sm')} {
		top: 58px;
		right: -51%;
	}
`;

export const MultiLeg = () => {
	const { control, watch, setValue } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'trip.legs.multiLeg',
	});

	const watchAllFields = watch();

	const setDateFrom = (name, value) => {
		setValue(name + 'dateFrom', value, { shouldValidate: true });
	};

	return (
		<Box>
			{fields.map((item, index) => {
				return (
					<Leg key={item.id} isLast={fields.length === index + 1} isFirst={index === 0}>
						<Row>
							<PlaceAndTimeBox>
								<FromToBox>
									<FirstCol>
										<AirportSelectMapBoxSearchBox
											name={`trip.legs.multiLeg[${index}].departure`}
											withHeading="From"
											icon="location-blue-light"
											placeholder="Your departure"
										/>
									</FirstCol>
									<SecondCol>
										<AirportSelectMapBoxSearchBox
											name={`trip.legs.multiLeg[${index}].destination`}
											withHeading="To"
											icon={
												watchAllFields.flightType?.constantKey === 'FLIGHTTYPE_ROUND_TRIP'
													? 'aircraft-multiple-blue-light'
													: 'aircraft-filled-blue-light'
											}
											placeholder="Your destination"
										/>
									</SecondCol>
								</FromToBox>

								<DatePickerColumn>
									<IconBox>
										<Icon type="calendar-blue-light2" />
									</IconBox>
									<DateTimePicker
										disabledDays={{
											before: getCurrentMoment().add(50, 'hours').toDate(),
										}}
										namePrefix={`trip.legs.multiLeg[${index}].`}
										setDateStart={setDateFrom}
										withLargeTitle
										placeholder="Departure"
										returnPlaceholder="Return"
										timezone="UTC"
										isRoundTrip={false}
										fromName="dateFrom"
									/>
								</DatePickerColumn>
								{index !== 0 && fields.length > MIN_TO_REMOVE && (
									<ButtonRemove data-testid="remove-leg" iconOnly onClick={() => remove(index)}>
										<Icon type="cross-circle-blue" />
									</ButtonRemove>
								)}
							</PlaceAndTimeBox>
						</Row>
					</Leg>
				);
			})}
			{fields.length < MAX_LEGS && (
				<AddButtonContainer>
					<AddButton
						iconOnly
						onClick={() => {
							const lastLeg = pick('trip.legs', watchAllFields)?.multiLeg[fields.length - 1];
							const newValues = {
								dateFrom: lastLeg.dateFrom,
								departure: lastLeg.destination,
							};

							append(newValues);
						}}
					>
						<StyledIcon type="plus-circle-blue" />
						Add another flight
					</AddButton>
				</AddButtonContainer>
			)}
		</Box>
	);
};
