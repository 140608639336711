import { toIsoStringAfterTzConvert } from '@fllite-fe/shared/src/utils/datetime';

import { FormValues } from '../RaF';

export const getLegs = (formValues: FormValues) => {
	let legs;
	switch (formValues.trip.flightType?.constantKey) {
		case 'FLIGHTTYPE_MULTI_LEG':
			legs = formValues.trip.legs.multiLeg.map((leg) => ({
				departureAirport: leg.departure,
				departureAt: toIsoStringAfterTzConvert(leg.dateFrom, leg.departure.value.timeZone),
				destinationAirport: leg.destination,
			}));
			break;
		case 'FLIGHTTYPE_ROUND_TRIP':
			legs = [
				{
					departureAirport: formValues.trip.legs.roundTrip.departure,
					departureAt: toIsoStringAfterTzConvert(
						formValues.trip.legs.roundTrip.dateFrom,
						formValues.trip.legs.roundTrip.departure.value.timeZone,
					),
					destinationAirport: formValues.trip.legs.roundTrip.destination,
					returnAt: toIsoStringAfterTzConvert(
						formValues.trip.legs.roundTrip.dateTo,
						formValues.trip.legs.roundTrip.destination.value.timeZone,
					),
				},
			];
			break;
		case 'FLIGHTTYPE_ONE_WAY':
			legs = [
				{
					departureAirport: formValues.trip.legs.oneWay.departure,
					departureAt: toIsoStringAfterTzConvert(
						formValues.trip.legs.oneWay.dateFrom,
						formValues.trip.legs.oneWay.departure.value.timeZone,
					),
					destinationAirport: formValues.trip.legs.oneWay.destination,
				},
			];
			break;
		default:
			legs = [];
			break;
	}
	return legs;
};
