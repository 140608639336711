import React from 'react';

import { CancelOutlined } from '@mui/icons-material';
import { Box, Paper, useMediaQuery } from '@mui/material';

import AutocompleteElement, {
	AutocompleteType,
} from '../../../mui-form-fields/AutocompleteElement';
import { MapBoxMenuOption } from '../../../mui-form-fields/AutocompleteElement/MapBoxMenuOption';
import { muiTheme } from '../../../muiTheme';
import { IconKeys } from '../../Icon';

export interface AirportSelectProps {
	name: string;
	icon: IconKeys;
	placeholder: string;
	withHeading?: string;
	withAnywhere?: boolean;
}

const CustomPaper = ({ airport, ...others }) => {
	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('sm'));

	return (
		<Paper
			{...others}
			sx={{ width: isDesktop ? '400px' : '100%', borderRadius: '5px', marginTop: '5px' }}
		>
			{airport ? (
				<Box sx={{ borderBottom: '1px solid rgb(220, 227, 234)', padding: '10px 17px' }}>
					<MapBoxMenuOption
						{...airport}
						selectHasIcon={true}
						isSelected={true}
						type={AutocompleteType.MapBoxSearch}
					/>
				</Box>
			) : (
				''
			)}
			{others.children}
		</Paper>
	);
};

const autocompleteSx = {
	'& button.MuiButtonBase-root': {
		visibility: 'visible',
	},
};

const textFieldSx = {
	'& .MuiInput-root': {
		paddingLeft: 0,
	},
};
const textFieldInputSx = { fontSize: '12px', fontWeight: 500 };

interface AirportSelectMapBoxSharedAutocompleteProps extends AirportSelectProps {
	data: any;
	isLoading: boolean;
	query: string;
	options: any;
	address: any;
	onChange: any;
	debouncedQuery: string;
	type: AutocompleteType;
}

export const AirportSelectMapBoxSharedAutocomplete = ({
	name,
	withHeading,
	icon,
	placeholder,
	options,
	isLoading,
	query,
	address,
	onChange,
	debouncedQuery,
	type,
}: AirportSelectMapBoxSharedAutocompleteProps) => (
	<AutocompleteElement
		name={name}
		withHeading={withHeading}
		icon={icon}
		options={options}
		loading={isLoading && !!query && address?.label !== debouncedQuery}
		isRaf
		customOnInputChange={onChange}
		autocompleteProps={{
			forcePopupIcon: false,
			clearIcon: <CancelOutlined sx={{ fontSize: 20 }} />,
			fullWidth: true,
			sx: autocompleteSx,
			PaperComponent: (props) => <CustomPaper airport={address} {...props} />,
			blurOnSelect: true,
			autoSelect: true,
			openOnFocus: true,
		}}
		type={type}
		textFieldProps={{
			variant: 'standard',
			placeholder,
			InputProps: { sx: textFieldInputSx },
			sx: textFieldSx,
		}}
	/>
);
