import { FC } from 'react';

import { Typography, useMediaQuery } from '@mui/material';

import { muiTheme } from '@fllite-fe/shared/src/muiTheme';

export const NoEstimationsInfo: FC = () => {
	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('md'));

	return (
		<Typography
			fontSize={12}
			fontWeight={500}
			color="#97989A"
			maxWidth="550px"
			textAlign={isDesktop ? 'right' : 'left'}
			mt={2}
		>
			Due to the number of passengers, we can't give you automated pricing estimations.
			<br />
			Please, contact us to get prices for your trip.
		</Typography>
	);
};
