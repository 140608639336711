import styled from '@emotion/styled';

export const HeaderContainer = styled('div')<{ marginBottom: string }>`
	width: 100%;
	margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const Subtitle = styled('div')`
	margin-top: 10px;
`;

export const Wrapper = styled('div')<{ maxWidth: string }>`
	display: flex;
	max-width: ${({ maxWidth }) => maxWidth};
	flex-direction: column;
`;
