import { FC, useMemo } from 'react';

import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModeEditOutlineOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useApiInquiriesPost } from '@fllite-fe/api';
import { muiTheme } from '@fllite-fe/shared/src/muiTheme';

import { AIRCRAFT_TYPES } from '../../const/aircraftTypes';
import theme from '../../customTheme';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useAnonymousUser } from '../../hooks/useAnonymousUser';
import { BUSINESS_CASE_QUERY_KEY_NAME, useAuthentication } from '../../hooks/useApi';
import { useEstimations } from '../../hooks/useEstimations';
import { getContextForApp } from '../../utils/config';
import { parseValuesForInquiryFromEstimation } from '../../utils/parseValuesForInquiry';
import { scrollToRaf } from '../../utils/scrollToRaf';
import { Button } from '../Button';
import { Card } from '../Card';
import { EducationalBanner } from '../EducationalLoader/EducationalBanner';
import { educationalBannerData } from '../EducationalLoader/educationalBannerData';
import { useFlashMessage } from '../FlashMessages';
import { LoadingContent } from '../LoadingContent';
import { Text } from '../Text';
import { AircraftTypeCard } from './AircraftTypeCard';
import { InfoText } from './InfoText';

const Description = styled(Typography)`
	color: ${({ theme }) => theme.colors.grayDarker};
	font-size: 14px;
	margin-bottom: 32px;
`;

const SubmitButtonWrapper = styled('div')`
	${({ theme }) => theme.breakpoints.down('md')} {
		display: flex;
		justify-content: center;
		width: 100%;
	}
`;

const schema = yup.object().shape({
	aircraftType: yup
		.array()
		.of(yup.string().required())
		.min(1, 'Please select at least one aircraft type to request this trip'),
});

interface Props {
	estimationIdToShow?: string;
	isLoading?: boolean;
	isHomepage?: boolean;
	callEditRequest?: () => void;
}

export const EstimationAircraftSelector: FC<Props> = ({
	estimationIdToShow,
	isLoading,
	isHomepage,
	callEditRequest,
}) => {
	const { track } = useAnalytics();
	const { value: anonymousUserId } = useAnonymousUser();
	const { user } = useAuthentication();

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: { aircraftType: [] },
	});
	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('md'));
	const { estimation, isLoading: estimationIsLoading } = useEstimations({
		estimationId: estimationIdToShow,
	});
	const { push } = useRouter();
	const { clientSectionUrl } = getContextForApp();
	const queryClient = useQueryClient();

	const { FlashMessageTypes, pushFlashMessage } = useFlashMessage();

	const { mutateAsync: postInquiry, isPending: isRequestingFlight } = useApiInquiriesPost({
		mutation: {
			onSuccess: (data) => {
				pushFlashMessage({
					type: FlashMessageTypes.Success,
					text: 'Flight request has been created.',
				});

				const QUERY_KEY = [BUSINESS_CASE_QUERY_KEY_NAME, data.businessCase.id, user?.id];
				queryClient.setQueryData(QUERY_KEY, { ...data.businessCase, inquiry: data });
			},
			onError: () => {
				pushFlashMessage({
					text: 'Could not create an inquiry',
					type: FlashMessageTypes.Error,
				});
			},
		},
	});

	const errors = methods.formState.errors;

	const onSubmit = async (values) => {
		const valuesToState = {
			...estimation,
			legs: estimation.estimationLegs,
			aircraftType: values.aircraftType,
		};
		const valueToSend = parseValuesForInquiryFromEstimation(valuesToState);

		track('inapp_aircraft_confirmation', {
			estimationId: valueToSend.estimation.id,
			destination: valuesToState.estimationLegs[0]?.destinationAirport?.airportCode,
			departure: valuesToState.estimationLegs[0]?.departureAirport?.airportCode,
			flightType: valueToSend.flightType.name,
			aircraftType: valuesToState.aircraftType,
			cookieID: anonymousUserId,
			userID: user?.id,
			page: 'calculator',
		});

		const res = await postInquiry({
			data: valueToSend,
		});

		if (clientSectionUrl) {
			push(`${clientSectionUrl}/detail/${res.businessCase?.id}`);
		} else {
			push(`/detail/${res.businessCase?.id}`);
		}
	};

	const allEstimationsAreDisabled = useMemo(
		() =>
			estimation?.estimationAircraftTypeGroups?.every(
				(aircraftTypeGroup) => !aircraftTypeGroup.minimumPrice && !aircraftTypeGroup.maximumPrice,
			),
		[JSON.stringify(estimation)],
	);

	if (!estimationIdToShow && !isLoading) {
		return null;
	}

	if (isLoading || estimationIsLoading) {
		return (
			<Box
				mb={3}
				borderRadius={isHomepage && '8px'}
				bgcolor={isHomepage && 'white'}
				padding={isHomepage && '24px 0 32px'}
			>
				<LoadingContent>
					<Text bold primary>
						Searching aircraft...
					</Text>
				</LoadingContent>
				<Box>
					<EducationalBanner content={educationalBannerData[Math.round(Math.random())]} noMargin />
				</Box>
			</Box>
		);
	}

	return (
		<Card title="Pricing Estimations" isDashboard>
			<Description>
				These are estimated prices for your trip. Select the aircraft type you want to request for
				this trip or change the request to see other pricing estimations.
			</Description>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Box>
						{errors.aircraftType && (
							<Typography color={theme.colors.danger} fontSize={12} fontWeight={500} mb={2}>
								{errors.aircraftType.message}
							</Typography>
						)}
					</Box>
					<Grid container spacing={{ xs: 4, md: 1 }} mt={2}>
						{AIRCRAFT_TYPES.map((aircraft) => {
							const aircraftTypeEstimation = estimation.estimationAircraftTypeGroups
								.find((item) => item.aircraftTypeDetailed.name === aircraft.name)
								?.estimationAircraftTypes?.sort(
									(a, b) => a.minimumPrice - b?.minimumPrice || a.maximumPrice - b.maximumPrice,
								)?.[0];

							return (
								<Grid item xs={12} md={4} key={aircraft.id}>
									<AircraftTypeCard
										aircraft={aircraft}
										priceFrom={aircraftTypeEstimation?.minimumPrice}
										priceTo={aircraftTypeEstimation?.maximumPrice}
										isHomepage={isHomepage}
										isRequestingFlight={isRequestingFlight}
									/>
								</Grid>
							);
						})}
					</Grid>
					<Divider sx={{ margin: '16px 0 ' }} />
					<Stack direction="row" justifyContent="space-between">
						{allEstimationsAreDisabled && (
							<Button
								text
								onClick={() => {
									scrollToRaf();
									callEditRequest();
								}}
							>
								<Stack direction="row" spacing={1} alignItems="center">
									<ModeEditOutlineOutlined fontSize="small" color="inherit" />
									<Typography fontSize={16} fontWeight={500} whiteSpace="nowrap">
										Edit Request
									</Typography>
								</Stack>
							</Button>
						)}
						<Box />
						<Stack
							direction="row"
							spacing={2}
							alignItems="center"
							{...(isDesktop ? {} : { width: '100%' })}
						>
							{isDesktop && <InfoText allEstimationsAreDisabled={allEstimationsAreDisabled} />}

							<SubmitButtonWrapper>
								{allEstimationsAreDisabled ? (
									<Link href="/contact-us?passengerOverfilled=true" legacyBehavior>
										<Button>Contact us</Button>
									</Link>
								) : (
									<Button secondary type="submit" isLoading={isRequestingFlight}>
										Request this Trip
									</Button>
								)}
							</SubmitButtonWrapper>
						</Stack>
					</Stack>
					{!isDesktop && <InfoText allEstimationsAreDisabled={allEstimationsAreDisabled} />}
				</form>
			</FormProvider>
		</Card>
	);
};
