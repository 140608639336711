import React, {
	FC,
	useCallback,
	useDeferredValue,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import styled from '@emotion/styled';
import { ConnectingAirportsOutlined, ModeEditOutlineOutlined } from '@mui/icons-material';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import type { GeoJSONSource } from 'mapbox-gl';
import MapBox, {
	FullscreenControl,
	Layer,
	MapRef,
	NavigationControl,
	Popup,
	Source,
	ViewStateChangeEvent,
} from 'react-map-gl';
import { throttle } from 'throttle-debounce';

import { useAnalytics } from '../../hooks/useAnalytics';
import { useAnonymousUser } from '../../hooks/useAnonymousUser';
import { useAuthentication } from '../../hooks/useAuthentication';
import { muiTheme } from '../../muiTheme';
import { Airport } from '../../types';
import { getContextForApp } from '../../utils/config';
import { Button } from '../Button';
import { Card } from '../Card';
import { SubmitMapProps } from '../RequestAFlightEmbedded/RaF';
import { AirportCodeChip } from './AirportCodeChip';
import {
	clusterCountLayer,
	clusterLayer,
	highlightedPointLayer,
	highlightedPointTextLayer,
	localPointLayer,
	localPointTextLayer,
	routeLayer,
	unclusteredPointLayer,
	unclusteredPointTextLayer,
} from './layers';
import { MapboxStyles } from './MapboxStyles';
import { popupContent } from './PopupContent';
import { getDistance } from './utils/getDistance';
import { getUnique } from './utils/getUnique';
import { isCenterInRadius } from './utils/isCenterInRadius';

const MAPBOX_TOKEN = getContextForApp().mapBoxAccessToken;
const TILESET_ID = getContextForApp().airportTilesetId;
const MAPBOX_STYLE = getContextForApp().mapBoxStyle;
const MAP_DEFAULT_ZOOM = 9;
const RADIUS_FOR_AIRPORT_CHIP_IN_MILES = 80;
const ONE_MILE_IN_METERS = 1609.34;
export const RADIUS_FOR_AIRPORTS = 40 * ONE_MILE_IN_METERS;
export const API_MAPBOX_BASE_URL = 'https://api.mapbox.com/v4';

const StyledButton = styled(Button)`
	width: 100%;
	${({ theme }) => theme.breakpoints.up('sm')} {
		width: auto;
	}
`;

interface Props {
	departure: {
		lat: number;
		lng: number;
	};
	destination: {
		lat: number;
		lng: number;
	};
	onSubmit: (data: SubmitMapProps) => void;
	selectedDestination?: GeoJSON.Feature;
	selectedDeparture?: GeoJSON.Feature;
	selectDestination: (data: GeoJSON.Feature) => void;
	selectDeparture: (data: GeoJSON.Feature) => void;
	closeMap: () => void;
}

type GeoJson = GeoJSON.FeatureCollection<GeoJSON.Geometry>;

export const AirportMap: FC<Props> = ({
	departure,
	destination,
	onSubmit,
	selectedDestination,
	selectedDeparture,
	selectDestination,
	selectDeparture,
	closeMap,
}) => {
	const { track } = useAnalytics();
	const { value: anonymousUserId } = useAnonymousUser();
	const { user } = useAuthentication();
	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('sm'));
	const [mapIsLoad, setMapIsLoad] = useState(false);
	const [popupFeatureToShow, setPopupFeatureToShow] = useState<GeoJSON.Feature>();

	const mapRef = useRef<MapRef>();

	const [departureIsActive, setDepartureIsActive] = useState(false);
	const [destinationIsActive, setDestinationIsActive] = useState(false);
	const [departureRadius, setDepartureRadius] = useState(RADIUS_FOR_AIRPORTS);
	const [destinationRadius, setDestinationRadius] = useState(RADIUS_FOR_AIRPORTS);
	const [isFullScreen, setIsFullScreen] = useState(false);

	const distance = useMemo(
		() => getDistance(departure.lat, departure.lng, destination.lat, destination.lng),
		[departure, destination],
	);
	const maxRadius = distance ? distance / 2 : undefined;

	const {
		data: departureAirports,
		isFetched: isFetchedDepartureAirports,
		isFetching: isLoadingDepartureAirports,
		refetch: refetchDepartureAirports,
	} = useQuery<GeoJson>({
		queryKey: ['features', departure.lat, departure.lng, departureRadius],
		queryFn: async () => {
			const data = await fetch(
				`${API_MAPBOX_BASE_URL}/${TILESET_ID}/tilequery/${departure.lng},${departure.lat}.json?limit=50&radius=${departureRadius}&access_token=${MAPBOX_TOKEN}`,
			);
			return data.json();
		},
	});

	const onMapLoad = useCallback(() => {
		if (mapRef.current) {
			setMapIsLoad(true);

			const target = {
				center: (selectedDeparture?.geometry as any)?.coordinates,
				zoom: MAP_DEFAULT_ZOOM,
				duration: 4000,
				essential: true,
			};

			setTimeout(() => {
				selectedDeparture && mapRef.current.flyTo(target);
			}, 1000);
		}
	}, [mapRef, selectedDeparture]);

	const {
		data: destinationAirports,
		isFetched: isFetchedDestinationAirports,
		isFetching: isLoadingDestinationAirports,
		refetch: refetchDestinationAirports,
	} = useQuery<GeoJson>({
		queryKey: ['features', destination.lat, destination.lng, destinationRadius],
		queryFn: async () => {
			const data = await fetch(
				`${API_MAPBOX_BASE_URL}/${TILESET_ID}/tilequery/${destination.lng},${destination.lat}.json?limit=50&radius=${destinationRadius}&access_token=${MAPBOX_TOKEN}`,
			);
			return data.json();
		},
	});

	useEffect(() => {
		if (!maxRadius) return;
		if (maxRadius < departureRadius) {
			setDepartureRadius(maxRadius);
			refetchDepartureAirports();
		}
		if (maxRadius < destinationRadius) {
			setDestinationRadius(maxRadius);
			refetchDestinationAirports();
		}
	}, [maxRadius]);

	useEffect(() => {
		if (!maxRadius) return;
		if (
			(!departureAirports || departureAirports?.features.length === 0) &&
			isFetchedDepartureAirports &&
			!isLoadingDepartureAirports &&
			departureRadius !== maxRadius
		) {
			setDepartureRadius((value) => {
				const newValue = value * 2.5;
				if (newValue > maxRadius) {
					return maxRadius;
				}
				return newValue;
			});
			refetchDepartureAirports();
		}

		if (
			(!destinationAirports || destinationAirports.features.length === 0) &&
			isFetchedDestinationAirports &&
			!isLoadingDestinationAirports &&
			destinationRadius !== maxRadius
		) {
			setDestinationRadius((value) => value * 2.5);
			refetchDestinationAirports();
		}
	}, [
		departureAirports,
		destinationAirports,
		isLoadingDepartureAirports,
		isLoadingDestinationAirports,
		isFetchedDepartureAirports,
		isFetchedDestinationAirports,
	]);

	const allAirportsFeatures = useMemo(() => {
		if (!departureAirports || !destinationAirports) {
			return [];
		}

		const airports = [
			...getUnique(departureAirports?.features?.length > 0 ? departureAirports.features : []),
			...getUnique(destinationAirports?.features?.length > 0 ? destinationAirports.features : []),
		];

		return airports;
	}, [departureAirports, destinationAirports]);

	const localAirportFeatures = useMemo(
		() =>
			allAirportsFeatures.filter(
				(item) =>
					item.properties['airport_category'] === 'local' &&
					item.id !== selectedDestination?.id &&
					item.id !== selectedDeparture?.id,
			),
		[allAirportsFeatures, selectedDestination, selectedDeparture],
	);

	const airportFeatures = useMemo(
		() =>
			!mapIsLoad
				? []
				: allAirportsFeatures?.filter(
						(item) =>
							item?.properties?.['airport_category'] !== 'local' &&
							item?.id !== selectedDestination?.id &&
							item?.id !== selectedDeparture?.id,
					) || [],
		[
			JSON.stringify(allAirportsFeatures),
			selectedDestination?.id,
			selectedDeparture?.id,
			mapIsLoad,
		],
	);

	const showPoints =
		allAirportsFeatures?.length > 0 &&
		(airportFeatures.length > 0 || (selectedDestination?.id && selectedDeparture?.id));

	useEffect(() => {
		if (destinationAirports?.features?.length > 0) {
			selectDestination(
				destinationAirports.features?.find(
					(airport) => airport?.properties?.['airport_category'] !== 'local',
				),
			);
		}
	}, [destinationAirports]);

	useEffect(() => {
		if (departureAirports?.features?.length > 0) {
			selectDeparture(
				departureAirports.features?.find(
					(airport) => airport?.properties?.['airport_category'] !== 'local',
				),
			);
		}
	}, [departureAirports]);

	const route = {
		type: 'FeatureCollection',
		features: [
			{
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: [
						(selectedDestination?.geometry as any)?.coordinates,
						(selectedDeparture?.geometry as any)?.coordinates,
					],
				},
			},
		],
	};

	const onMapClick = (e) => {
		setPopupFeatureToShow(undefined);
		const features = mapRef.current.queryRenderedFeatures(e.point, {
			layers:
				localAirportFeatures?.length > 0
					? ['clusters', 'filtered-airports', 'local-airports']
					: ['clusters', 'filtered-airports'],
		});

		if (!features.length) {
			return;
		}

		const feature = features[0];

		const clusterId = feature.properties.cluster_id;

		if (clusterId) {
			const mapboxSource = mapRef.current.getSource('airports') as GeoJSONSource;

			mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
				if (err) {
					return;
				}

				mapRef.current.easeTo({
					center: (feature?.geometry as any).coordinates,
					zoom,
					duration: 500,
				});
			});
		} else if (departureAirports.features.find((f) => f.properties.id === feature.properties.id)) {
			selectDeparture(feature);
			setPopupFeatureToShow(feature);
		} else {
			selectDestination(feature);
			setPopupFeatureToShow(feature);
		}
	};

	const handleSubmit = () => {
		track('inapp_airports_confirmation', {
			cookieID: anonymousUserId,
			userID: user?.id,
			page: 'calculator',
			departure: selectedDeparture.properties?.airportCode,
			destination: selectedDestination.properties?.airportCode,
		});

		onSubmit({
			departureAirport: selectedDeparture.properties as Airport,
			destinationAirport: selectedDestination.properties as Airport,
		});
	};

	const clickOnAirportChip = (airport: GeoJSON.Feature) => {
		if (mapRef.current && airport?.geometry) {
			const target = {
				center: (airport?.geometry as any)?.coordinates,
				zoom: MAP_DEFAULT_ZOOM,
				duration: 2000,
				essential: true,
			};
			mapRef.current.flyTo(target);
		}
	};

	const onMove = throttle(500, (e: ViewStateChangeEvent) => {
		if (selectedDeparture) {
			setDepartureIsActive(
				isCenterInRadius(
					(selectedDeparture?.geometry as any).coordinates[1],
					(selectedDeparture?.geometry as any).coordinates[0],
					e.viewState.latitude,
					e.viewState.longitude,
					RADIUS_FOR_AIRPORT_CHIP_IN_MILES,
				),
			);
		}

		if (selectedDestination) {
			setDestinationIsActive(
				isCenterInRadius(
					(selectedDestination?.geometry as any).coordinates[1],
					(selectedDestination?.geometry as any).coordinates[0],
					e.viewState.latitude,
					e.viewState.longitude,
					RADIUS_FOR_AIRPORT_CHIP_IN_MILES,
				),
			);
		}
	});

	const isLoadingAirports = useMemo(
		() => isLoadingDepartureAirports || isLoadingDestinationAirports,
		[isLoadingDepartureAirports, isLoadingDestinationAirports],
	);

	const isLoadingAirportsDeferred = useDeferredValue(isLoadingAirports);
	const popupCoordinates = (popupFeatureToShow?.geometry as any)?.coordinates;
	const popupContentByType = popupContent.find(
		(item) => item.airport_category === popupFeatureToShow?.properties?.airport_category,
	);

	return (
		<Card
			title={
				<Stack
					direction={{ xs: 'column', md: 'row' }}
					spacing={2}
					alignItems={{ xs: 'flex-start', md: 'center' }}
					width="100%"
				>
					<Typography color="primary" fontSize={20} fontWeight={600} flexShrink={0} order={0}>
						Confirm the airports:
					</Typography>
					<Stack
						direction="row"
						spacing={1}
						alignItems="center"
						order={{ xs: 2, md: 1 }}
						width={{ xs: '100%', md: 'auto' }}
					>
						<AirportCodeChip
							label={selectedDeparture?.properties.airportCode ?? '-'}
							active={departureIsActive}
							onClick={() => clickOnAirportChip(selectedDeparture)}
						/>
						<ConnectingAirportsOutlined fontSize="small" sx={{ color: '#8CC0FF' }} />
						<AirportCodeChip
							label={selectedDestination?.properties.airportCode ?? '-'}
							active={destinationIsActive}
							onClick={() => clickOnAirportChip(selectedDestination)}
							isDestination
						/>
					</Stack>
					<Typography
						textAlign="left"
						color="#97989A"
						fontSize={10}
						fontWeight={500}
						order={{ xs: 1, md: 2 }}
					>
						Click on the airport code on the left to zoom in,
						<br /> then click directly on the airport on the map to select a new departure or
						destination airport.
					</Typography>
				</Stack>
			}
			isDashboard
			isFullScreen={isFullScreen}
		>
			<MapboxStyles
				height={{ xs: 'calc(100% - 250px)', sm: 'calc(100% - 200px)', md: 'calc(100% - 120px)' }}
			>
				<MapBox
					ref={mapRef}
					initialViewState={{
						longitude: departure.lng,
						latitude: departure.lat,
						zoom: MAP_DEFAULT_ZOOM,
						bounds: [
							[departure.lng, departure.lat],
							[destination.lng, destination.lat],
						],
						fitBoundsOptions: { padding: 50 },
					}}
					style={{ height: '100%', minHeight: isDesktop ? '600px' : '400px' }}
					mapboxAccessToken={MAPBOX_TOKEN}
					onLoad={onMapLoad}
					onClick={onMapClick}
					mapStyle={MAPBOX_STYLE}
					interactiveLayerIds={[clusterLayer.id]}
					onMove={onMove}
					cooperativeGestures
					onResize={() => {
						if (document.fullscreenElement) {
							setIsFullScreen(true);
						} else {
							setIsFullScreen(false);
						}
					}}
				>
					{!!popupFeatureToShow && (
						<Popup
							key={`${popupCoordinates.toString()}`}
							longitude={popupCoordinates[0]}
							latitude={popupCoordinates[1]}
							anchor={isDesktop ? 'bottom' : 'top'}
							onClose={() => setPopupFeatureToShow(undefined)}
						>
							<Typography fontSize={14} fontWeight={600} mb="2px">
								{popupFeatureToShow.properties.name}
							</Typography>
							<Stack direction="row" alignItems="center" spacing={1} mb="2px">
								{popupContentByType?.icon}
								<Typography fontSize={12} fontWeight={600} color="primary">
									{popupContentByType?.name}
								</Typography>
							</Stack>

							<Typography fontSize={12} fontWeight={400}>
								{popupContentByType?.description}
							</Typography>
						</Popup>
					)}
					{isDesktop && <NavigationControl position="bottom-right" showCompass={false} />}
					<FullscreenControl position="bottom-right" containerId="raf-map" />

					{showPoints && (
						<>
							{localAirportFeatures.length > 0 && (
								<Source
									id="local-airports-source"
									type="geojson"
									data={{
										...departureAirports,
										features: localAirportFeatures,
									}}
								>
									<Layer {...localPointLayer} />
									<Layer {...localPointTextLayer} />
								</Source>
							)}

							<Source
								id="airports"
								type="geojson"
								data={{
									...departureAirports,
									features: airportFeatures,
								}}
								cluster={true}
								clusterMaxZoom={10}
								clusterRadius={25}
							>
								<Layer {...unclusteredPointLayer} />
								<Layer {...unclusteredPointTextLayer} />
								<Layer {...clusterLayer} />
								<Layer {...clusterCountLayer} />
							</Source>

							<Source type="geojson" data={route as GeoJson}>
								<Layer {...routeLayer} />
							</Source>
							{selectedDestination && selectedDeparture && (
								<Source
									type="geojson"
									data={{
										...destinationAirports,
										features: [selectedDestination, selectedDeparture],
									}}
								>
									<Layer {...highlightedPointLayer} />
									<Layer {...highlightedPointTextLayer} />
								</Source>
							)}
						</>
					)}
				</MapBox>
			</MapboxStyles>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				borderTop="1px solid #DDDFE3"
				spacing={1}
				pt={2}
			>
				<Button text onClick={closeMap} data-testid="edit-request">
					<Stack direction="row" spacing={1} alignItems="center">
						<ModeEditOutlineOutlined fontSize="small" color="inherit" />
						<Typography fontSize={16} fontWeight={500} display={{ xs: 'none', sm: 'inline' }}>
							Edit Request
						</Typography>
					</Stack>
				</Button>
				<Stack direction="column" spacing={1}>
					<StyledButton
						secondary
						onClick={handleSubmit}
						data-testid="submit-map"
						disabled={!selectedDeparture || !selectedDestination}
						isLoading={isLoadingAirportsDeferred}
					>
						Confirm & Continue
					</StyledButton>
					{!isLoadingAirportsDeferred && (!selectedDeparture || !selectedDestination) ? (
						<Typography fontSize={12} fontWeight={500} color="error.main">
							Please select both departure and destination airports
						</Typography>
					) : null}
				</Stack>
			</Stack>
		</Card>
	);
};
