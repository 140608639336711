import { useFormContext } from 'react-hook-form';

import DateTimePicker from '../../../form-fields/DateTimePicker';
import { getCurrentMoment } from '../../../utils/datetime';
import { Icon } from '../../Icon';
import {
	DatePickerColumn,
	FirstCol,
	FromToBox,
	IconBox,
	PlaceAndTimeBox,
	SecondCol,
} from '../RaFForm.styles';
import { AirportSelectMapBoxSearchBox } from './AirportSelectMapBoxSearchBox';

export const RoundTrip = () => {
	const { watch, setValue } = useFormContext();
	const watchAllFields = watch();

	const setDateFrom = (name, value) => {
		setValue(name + 'dateFrom', value, { shouldValidate: false });
	};

	const setDateTo = (name, value) => {
		setValue(name + 'dateTo', value, { shouldValidate: false });
	};

	return (
		<PlaceAndTimeBox hasMargin>
			<FromToBox>
				<FirstCol>
					<AirportSelectMapBoxSearchBox
						name="trip.legs.roundTrip.departure"
						withHeading="From"
						icon="location-blue-light"
						placeholder="Your departure"
					/>
				</FirstCol>
				<SecondCol>
					<AirportSelectMapBoxSearchBox
						name="trip.legs.roundTrip.destination"
						withHeading="To"
						icon={
							watchAllFields.flightType?.constantKey === 'FLIGHTTYPE_ROUND_TRIP'
								? 'aircraft-multiple-blue-light'
								: 'aircraft-filled-blue-light'
						}
						placeholder="Your destination"
					/>
				</SecondCol>
			</FromToBox>

			<DatePickerColumn>
				<IconBox>
					<Icon type="calendar-blue-light2" />
				</IconBox>
				<DateTimePicker
					disabledDays={{
						before: getCurrentMoment().add(50, 'hours').toDate(),
					}}
					namePrefix="trip.legs.roundTrip."
					isRoundTrip
					setDateStart={setDateFrom}
					setDateEnd={setDateTo}
					destinationTimezone="UTC"
					withLargeTitle
					placeholder="Departure"
					returnPlaceholder="Return"
					timezone="UTC"
					fromName="dateFrom"
					toName="dateTo"
				/>
			</DatePickerColumn>
		</PlaceAndTimeBox>
	);
};
