import { FC, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';
import {
	Box,
	Checkbox,
	Collapse,
	FormControlLabel,
	Stack,
	Typography,
	useMediaQuery,
} from '@mui/material';
import Image from 'next/image';
import { useFormContext } from 'react-hook-form';

import { AIRCRAFT_TYPES } from '../../const/aircraftTypes';
import theme from '../../customTheme';
import { muiTheme } from '../../muiTheme';
import { formatPrice } from '../../utils/formatters';
import { getCeiledPriceDivisibleBy100, getFlooredPriceDivisibleBy100 } from '../../utils/price';
import { Button } from '../Button';
import { CustomChip } from './CustomChip';

const Content = styled(Box)<{ isError: boolean; isChecked: boolean; isDisabled: boolean }>`
	border: 1px solid
		${({ isError, theme, isChecked }) =>
			isError ? theme.colors.danger : isChecked ? theme.colors.primary : '#dddfe3'};
	border-radius: 8px;
	background: ${({ isChecked }) => (isChecked ? '#E8F2FF' : '#fafafa')};
	width: 100%;
	padding: 8px 16px;
	transition:
		border-color 0.2s ease-in-out,
		background 0.2s ease-in-out;
	cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
	position: relative;
	height: 100%;
`;

const PriceItemWrapper = styled('div')`
	white-space: nowrap;
	display: inline-block;
	display: flex;
	align-items: center;
`;

const Price = styled(Box)<{ isChecked: boolean; isHomepage?: boolean }>`
	padding: 16px 8px;
	background: ${({ isChecked }) => (isChecked ? 'white' : '#edf0f4')};
	border-radius: 0px 24px 24px 24px;
	transition: background 0.2s ease-in-out;
`;

const StyledImageBig = styled(Image)<{ isDisabled?: boolean }>`
	object-fit: cover;
	border-radius: 8px;
	filter: ${({ isDisabled }) => (isDisabled ? 'grayScale(1)' : 'none')};
`;

interface Props {
	aircraft: (typeof AIRCRAFT_TYPES)[0];
	priceFrom?: number;
	priceTo?: number;
	isHomepage?: boolean;
	isRequestingFlight?: boolean;
}

export const AircraftTypeCard: FC<Props> = ({
	aircraft,
	priceFrom,
	priceTo,
	isHomepage,
	isRequestingFlight,
}) => {
	const [expanded, setExpanded] = useState(false);

	const infoList = useMemo(
		() => (
			<Stack spacing={1}>
				{aircraft.infoList.map((item) => (
					<Stack direction="row" spacing={1} alignItems="flex-start" key={item}>
						<CheckCircleOutlineOutlined
							color="success"
							sx={{ fontSize: '16px', marginTop: '4px !important' }}
						/>
						<Typography>{item}</Typography>
					</Stack>
				))}
			</Stack>
		),
		[aircraft],
	);
	const { register, formState, watch } = useFormContext();

	const aircraftTypeValue = watch('aircraftType');

	const handleExpandClick = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setExpanded(!expanded);
	};

	const flooredMinPrice = getFlooredPriceDivisibleBy100(priceFrom);
	const ceiledMaxPrice = getCeiledPriceDivisibleBy100(priceTo);

	const isChecked = aircraftTypeValue?.includes(aircraft.id.toString());

	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('sm'));

	const isError = !!formState.errors.aircraftType;

	const isDisabled = !priceFrom && !priceTo;

	return (
		<label htmlFor={`aircraftType-${aircraft.id}`}>
			<Content isError={isError} isChecked={isChecked} isDisabled={isDisabled}>
				{!isDisabled && <CustomChip aircraftType={aircraft.name} />}

				<Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
					<FormControlLabel
						control={
							<Checkbox
								size="small"
								{...register('aircraftType')}
								value={aircraft.id}
								sx={{
									...(isError ? { color: theme.colors.danger } : {}),
								}}
								checked={isChecked}
								id={`aircraftType-${aircraft.id}`}
								disabled={isRequestingFlight || isDisabled}
							/>
						}
						label={aircraft.name}
						checked={isChecked}
						sx={{ '& .MuiTypography-root': { fontWeight: 'bold' } }}
					/>
					{!isDisabled && (
						<Price isChecked={isChecked} isHomepage={isHomepage}>
							<Stack
								alignItems="center"
								spacing="4px"
								direction="row"
								flexWrap="wrap"
								justifyContent={{ xs: 'center', sm: 'unset' }}
							>
								<PriceItemWrapper>
									<Typography fontSize={12} mr="4px">
										from
									</Typography>
									<Typography fontWeight={600}>{formatPrice(flooredMinPrice, 0)}</Typography>
								</PriceItemWrapper>
								<PriceItemWrapper>
									<Typography fontSize={12} mr="4px">
										to
									</Typography>
									<Typography fontWeight={600}>{formatPrice(ceiledMaxPrice, 0)}</Typography>
								</PriceItemWrapper>
							</Stack>
						</Price>
					)}
				</Stack>
				<Box position="relative" width="100%" height="172px" mt={1}>
					<StyledImageBig src={aircraft.image} alt={aircraft.name} fill isDisabled={isDisabled} />
				</Box>
				{isDisabled ? (
					<Box mt={1}>
						<Typography fontSize={12} textAlign="center" lineHeight="25px">
							You can’t select <strong>{aircraft.name}</strong> for your request, because its
							maximum capacity is <strong>{aircraft.maximumSeats}</strong> passengers.
						</Typography>
						<Typography fontSize={12} textAlign="center" mt={1} lineHeight="25px">
							Select another aircraft or decrease the number of passengers in your trip.
						</Typography>
					</Box>
				) : (
					<Box mt={1}>
						{isDesktop ? (
							infoList
						) : (
							<>
								<Collapse in={expanded} timeout="auto" unmountOnExit>
									<Box mb={1}>{infoList}</Box>
								</Collapse>
								<Box textAlign="center">
									<Button text onClick={handleExpandClick}>
										{expanded ? 'Hide' : 'See'} Aircraft Details
									</Button>
								</Box>
							</>
						)}
					</Box>
				)}
			</Content>
		</label>
	);
};
