import { ArrowForward } from '@mui/icons-material';
import { Box, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material';
import Link from 'next/link';

import LegsInfo from '@fllite-fe/shared/src/components/LegsInfo';
import { useFlights } from '@fllite-fe/shared/src/hooks/useFlights';
import { muiTheme } from '@fllite-fe/shared/src/muiTheme';
import { BusinessCase } from '@fllite-fe/shared/src/types';

export const UpcomingTrip = () => {
	const { isLoading: isLoadingFlights, myTrips } = useFlights();
	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('sm'));

	if (myTrips.length === 0 && !isLoadingFlights) return null;

	return (
		<Box mb={4}>
			<Stack justifyContent="space-between" direction="row" mb={2}>
				<Typography fontSize={16} fontWeight={600} color={{ xs: '#002553', sm: 'white' }}>
					Upcoming Trip
				</Typography>
				<Link href="/my-flights" passHref>
					<Typography fontSize={16} fontWeight={600} color={{ xs: '#002553', sm: 'white' }}>
						<Stack direction="row" alignItems="center" spacing={1}>
							View All Trips
							<ArrowForward fontSize="small" color="inherit" />
						</Stack>
					</Typography>
				</Link>
			</Stack>
			{isLoadingFlights ? (
				<Skeleton variant="rectangular" height={isDesktop ? 100 : 400} />
			) : (
				myTrips.length > 0 && (
					<LegsInfo businessCase={myTrips[0] as BusinessCase} smallTicket isUpcomingTrip inverted />
				)
			)}
		</Box>
	);
};
