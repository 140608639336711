import { FC, useEffect, useState } from 'react';

import { Box, Collapse } from '@mui/material';
import { useRouter } from 'next/router';
import { scroller } from 'react-scroll';

import { RafFormValues, useAuthentication } from '..';
import { useEstimations } from '../hooks/useEstimations';
import { EstimationPostResult } from '../types';
import { parseValuesForEstimation } from '../utils/parseValuesForInquiry';
import { transformEstimationToRaf } from '../utils/transformEstimationToRaf';
import { EstimationAircraftSelector } from './EstimationAircraftSelector';
import { RaF } from './RequestAFlightEmbedded/RaF';
import { ScrollElement } from './ScrollElement';

interface HeroRequestAFlightProp {
	isHomepage?: boolean;
	estimationToPrefill?: EstimationPostResult;
	onExtendedInfoOpen?: () => void;
	onExtendedInfoClose?: () => void;
}

export const RequestAFlightApp: FC<HeroRequestAFlightProp> = ({
	isHomepage,
	estimationToPrefill,
	onExtendedInfoOpen,
	onExtendedInfoClose,
}) => {
	const { query, pathname, replace } = useRouter();
	const { user, refetchUser } = useAuthentication();
	const [isRequestingFlight, setIsRequestingFlight] = useState(false);
	const [estimationIdToShow, setEstimationIdToShow] = useState<string>();
	const [editRequestGeneration, setEditRequestGeneration] = useState(0);

	const prefillForm = !!query['prefillForm'];

	const scrollToEstimations = () => {
		scroller.scrollTo('estimations', {
			duration: 1500,
			delay: 100,
			smooth: true,
			offset: -150,
		});
	};

	const refetchUserAndEstimate = async () => {
		const res = await refetchUser();

		if (res?.data?.lastEstimation?.id) {
			setEstimationIdToShow(res?.data?.lastEstimation?.id);
			scrollToEstimations();
		}
		replace(pathname, undefined, { shallow: true });
	};

	useEffect(() => {
		if (prefillForm) {
			refetchUserAndEstimate();
		}
	}, [prefillForm, user.lastEstimation?.id]);

	useEffect(() => {
		if (estimationToPrefill) {
			setEstimationIdToShow(estimationToPrefill.id);
			scrollToEstimations();
		}
	}, [estimationToPrefill]);

	const { estimate, estimation } = useEstimations({ estimationId: estimationIdToShow });

	const handleFormSubmit = async (values: RafFormValues) => {
		setIsRequestingFlight(true);
		setTimeout(() => {
			scrollToEstimations();
		}, 500);

		const inquiryData = parseValuesForEstimation({
			flightType: values.flightType,
			numberOfSeats: { passengers: values.numberOfSeats?.passengers },
			legs: values.legs,
			aircraftType: values.aircraftType,
		});

		const res = await estimate({ data: inquiryData });
		replace(pathname, undefined, { shallow: true });

		setEstimationIdToShow(res.id);
		setIsRequestingFlight(false);
	};

	const callEditRequest = () => {
		setEditRequestGeneration((prev) => prev + 1);
	};

	return (
		<>
			<RaF
				isLoading={isRequestingFlight}
				onSubmit={handleFormSubmit}
				defaultValues={
					!!estimation && {
						...transformEstimationToRaf(estimation),
					}
				}
				closeEstimate={() => setEstimationIdToShow(undefined)}
				isHomepage={isHomepage}
				editRequestGeneration={editRequestGeneration}
				onExtendedInfoOpen={onExtendedInfoOpen}
				onExtendedInfoClose={onExtendedInfoClose}
			/>

			<Box mt={8}>
				<ScrollElement nameElement="estimations">
					<Collapse in={!!estimationIdToShow || isRequestingFlight} timeout={500} unmountOnExit>
						<EstimationAircraftSelector
							isLoading={isRequestingFlight}
							estimationIdToShow={estimationIdToShow}
							isHomepage={isHomepage}
							callEditRequest={callEditRequest}
						/>
					</Collapse>
				</ScrollElement>
			</Box>
		</>
	);
};
