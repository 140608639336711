import { FC, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Collapse, useMediaQuery } from '@mui/material';
import { useRouter } from 'next/router';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { scroller } from 'react-scroll';

import { RafFormValues } from '../../hooks/useAccountContext';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useAnonymousUser } from '../../hooks/useAnonymousUser';
import { useAircraft } from '../../hooks/useApi';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useMapBoxSearchBoxRetrieve } from '../../hooks/useMapBoxSearchBox';
import { RadioButtonIcon } from '../../mui-form-fields/RadioButtonGroup';
import { muiTheme } from '../../muiTheme';
import { Airport } from '../../types';
import { getContextForApp } from '../../utils/config';
import { formatAirportName } from '../../utils/formatters';
import { getEstimationLegsFromRaF } from '../../utils/parseValuesForInquiry';
import { scrollToRaf } from '../../utils/scrollToRaf';
import { LegsInfoCardSimple } from '../LegsInfo/LegsInfoCardSimple';
import { AirportMap } from '../MapBox/AirportMap';
import { FormLeg } from '../RequestAFlight/types';
import { ScrollElement } from '../ScrollElement';
import { TripInfoCardMobile } from '../TripInfoCard/TripInfoCardMobile';
import { Container, GradientStripe } from './RaFForm.styles';
import { TripFormGroup } from './TripFormGroup';
import { getLegs } from './utils/getLegs';
import { parseFormValues } from './utils/parseFormValues';
import { schema } from './validationSchema';

export interface FormValues {
	trip?: {
		aircraftType?: Array<string>;
		flightType?: {
			value: number;
			label: string;
			constantKey: string;
		};
		legs: {
			multiLeg?: FormLeg[];
			oneWay?: FormLeg;
			roundTrip?: FormLeg;
		};
		seats: number;
	};
	calculationOnly?: string;
	calculationInquiry?: string;
}

export interface SubmitMapProps {
	departureAirport: Airport;
	destinationAirport: Airport;
}

interface RaFProps {
	onSubmit?: (values: RafFormValues) => void;
	isLoading?: boolean;
	defaultValues?: any;
	closeEstimate?: () => void;
	isHomepage?: boolean;
	editRequestGeneration?: number;
	onExtendedInfoOpen?: () => void;
	onExtendedInfoClose?: () => void;
}

const defaultFlightTypeOptions = [
	{
		label: 'One Way',
		value: 1,
		constantKey: 'FLIGHTTYPE_ONE_WAY',
		icon: RadioButtonIcon.OneWay,
	},
	{
		label: 'Round Trip',
		value: 2,
		constantKey: 'FLIGHTTYPE_ROUND_TRIP',
		icon: RadioButtonIcon.RoundTrip,
	},
];

export const RaF: FC<RaFProps> = ({
	onSubmit,
	isLoading = false,
	defaultValues,
	closeEstimate,
	isHomepage,
	editRequestGeneration,
	onExtendedInfoOpen,
	onExtendedInfoClose,
}) => {
	const { detailedAircraftTypeOptions } = useAircraft();
	const { track } = useAnalytics();
	const { query, push } = useRouter();
	const prefillForm = !!query['prefillForm'];
	const { clientSectionUrl } = getContextForApp();

	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('md'));
	const { user } = useAuthentication();
	const { value: anonymousUserId } = useAnonymousUser();

	const scrollToRafMap = () => {
		scroller.scrollTo('raf-map', {
			duration: 1500,
			delay: 100,
			smooth: true,
			offset: -150,
		});
	};

	const [departureMapboxId, setDepartureMapboxId] = useState<string>();
	const [destinationMapboxId, setDestinationMapboxId] = useState<string>();
	const [formWasFilled, setFormWasFilled] = useState(false);

	const [selectedDestination, setSelectedDestination] = useState<GeoJSON.Feature>();
	const [selectedDeparture, setSelectedDeparture] = useState<GeoJSON.Feature>();
	const [formValues, setFormValues] = useState<FormValues>();
	const [showMap, setShowMap] = useState(false);
	const [showSmallCard, setShowSmallCard] = useState(false);

	const [departureCenterDefault, setDepartureCenterDefault] = useState<[number, number]>();
	const [destinationCenterDefault, setDestinationCenterDefault] = useState<[number, number]>();

	const departureCenter =
		useMapBoxSearchBoxRetrieve({ mapbox_id: departureMapboxId })?.data?.geometry?.coordinates ??
		departureCenterDefault;

	const destinationCenter =
		useMapBoxSearchBoxRetrieve({ mapbox_id: destinationMapboxId })?.data?.geometry?.coordinates ??
		destinationCenterDefault;

	useEffect(() => {
		if (!!departureCenter && !!destinationCenter && formWasFilled) {
			setShowMap(true);
			setShowSmallCard(true);
			setTimeout(() => {
				scrollToRafMap();
			}, 500);
		}
	}, [departureCenter, destinationCenter, formWasFilled]);

	const defaultDeparture =
		user?.homebaseAirport && !isHomepage
			? {
					label: formatAirportName(user?.homebaseAirport),
					value: user.homebaseAirport,
				}
			: undefined;

	const defaultDepartureMapBox = user?.homebaseAirport
		? {
				label: formatAirportName(user?.homebaseAirport),
				value: {
					center: [user.homebaseAirport.longitude, user.homebaseAirport.latitude],
					place_name: formatAirportName(user?.homebaseAirport),
				},
			}
		: undefined;

	const initialValues = useMemo(() => {
		if (formValues) {
			return formValues;
		}
		if (defaultValues) {
			return defaultValues;
		}
		return {
			trip: {
				flightType: defaultFlightTypeOptions.find(
					(option) => option.constantKey === 'FLIGHTTYPE_ROUND_TRIP',
				),
				aircraftType:
					detailedAircraftTypeOptions.length > 0
						? detailedAircraftTypeOptions.map((a) => a.value.toString())
						: [],
				seats: 1,
				legs: {
					multiLeg: [
						{
							departure: defaultDeparture,
							dateFrom: undefined,
						},
						{
							dateFrom: undefined,
						},
					],
					oneWay: {
						departure: defaultDepartureMapBox,
						dateFrom: undefined,
					},
					roundTrip: {
						departure: defaultDepartureMapBox,
						dateFrom: undefined,
						dateTo: undefined,
					},
				},
			},
		};
	}, [JSON.stringify(detailedAircraftTypeOptions), JSON.stringify(defaultValues)]);

	const formContext = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema),
		values: initialValues,
		resetOptions: {
			keepDirtyValues: true,
		},
	});

	useEffect(() => {
		if (initialValues.calculationInquiry) {
			handleFormSubmit(initialValues);
			formContext.reset(initialValues, { keepDirty: false });
		}
	}, [JSON.stringify(initialValues)]);

	const handleFormSubmit = ({ departureAirport, destinationAirport }: SubmitMapProps) => {
		const parsedValues = parseFormValues(formValues, departureAirport, destinationAirport);

		track(user?.id ? 'raf_submit_inapp' : 'raf_submit_public', {
			page: user?.id ? 'calculator' : 'public_flow',
			userID: user?.id,
			cookieID: anonymousUserId,
			departure: parsedValues.legs[0].departureAirport.value.airportCode,
			destination:
				parsedValues.legs[parsedValues.legs.length - 1].destinationAirport.value.airportCode,
			departure_datetime: parsedValues.legs[0].departureAt,
			destination_datetime: parsedValues.legs[parsedValues.legs.length - 1].returnAt,
			number_of_seat: parsedValues.numberOfSeats.passengers,
		});

		!!user && setShowMap(false);
		onSubmit(parsedValues);
	};

	useEffect(() => {
		if (!user?.id) return;
		track('raf', {
			userID: user.id,
			cookieID: anonymousUserId,
			page: 'dashboard',
		});
	}, [user?.id]);

	useEffect(() => {
		if (defaultValues && prefillForm) {
			const legs = getLegs(defaultValues);
			const departureValue = legs[0].departureAirport.value;
			const destinationValue = legs[0].destinationAirport.value;

			setSelectedDestination({ properties: destinationValue } as GeoJSON.Feature);
			setSelectedDeparture({ properties: departureValue } as GeoJSON.Feature);

			setShowSmallCard(true);
			setFormValues(defaultValues);
			setDepartureCenterDefault([departureValue.longitude, departureValue.latitude]);
			setDestinationCenterDefault([destinationValue.longitude, destinationValue.latitude]);
		}
	}, [defaultValues]);

	const handleRAFSubmit = async (values) => {
		if (isHomepage && user && !user?.confirmed) {
			push(clientSectionUrl);
			return;
		}

		closeEstimate && closeEstimate();
		const legs = getLegs(values);
		setFormValues(values);
		const parsedValues = getEstimationLegsFromRaF(values);
		track(user?.id ? 'raf_searching_inapp' : 'raf_searching_public', {
			page: user?.id ? 'calculator' : 'public_flow',
			userID: user?.id,
			cookieID: anonymousUserId,
			departure: parsedValues[0].departureAirport.label,
			destination: parsedValues[parsedValues.length - 1].destinationAirport.label,
			departure_datetime: parsedValues[0].departureAt,
			number_of_seat: values.trip.seats,
		});

		const departureValue = legs[0].departureAirport.value;
		const destinationValue = legs[0].destinationAirport.value;

		if (departureValue.center) {
			setDepartureCenterDefault(departureValue.center);
		} else if (departureValue?.latitude) {
			setDepartureCenterDefault([departureValue.longitude, departureValue.latitude]);
		} else {
			setDepartureCenterDefault(undefined);
		}

		if (destinationValue.center) {
			setDestinationCenterDefault(destinationValue.center);
		} else if (destinationValue?.latitude) {
			setDestinationCenterDefault([destinationValue.longitude, destinationValue.latitude]);
		} else {
			setDestinationCenterDefault(undefined);
		}

		setDestinationMapboxId(legs[0].destinationAirport.value.mapbox_id);
		setDepartureMapboxId(legs[0].departureAirport.value.mapbox_id);
		setFormWasFilled(true);
		onExtendedInfoOpen && onExtendedInfoOpen();
	};

	const selectDestination = (feature: GeoJSON.Feature) => {
		setSelectedDestination(feature);
	};

	const selectDeparture = (feature: GeoJSON.Feature) => {
		setSelectedDeparture(feature);
	};

	const editRequest = () => {
		setShowMap(false);
		setShowSmallCard(false);
		closeEstimate && closeEstimate();
		onExtendedInfoClose && onExtendedInfoClose();
		scrollToRaf();
		setFormWasFilled(false);
	};

	useEffect(() => {
		if (!editRequestGeneration) return;
		editRequest();
	}, [editRequestGeneration]);

	const isOneWay = formValues?.trip?.flightType?.constantKey === 'FLIGHTTYPE_ONE_WAY';
	const isRoundTrip = formValues?.trip?.flightType?.constantKey === 'FLIGHTTYPE_ROUND_TRIP';
	const parsedLegs = useMemo(() => {
		if (!formValues) return undefined;
		return getLegs(formValues);
	}, [formValues]);

	const isSmallCardVisible =
		showSmallCard && formValues && selectedDestination && selectedDeparture;

	return (
		<>
			<ScrollElement nameElement="raf">
				{isSmallCardVisible ? (
					isDesktop ? (
						<LegsInfoCardSimple
							firstLeg={{
								...parsedLegs[0],
								departureAirport: selectedDeparture?.properties,
								destinationAirport: selectedDestination?.properties,
							}}
							returnLeg={
								!isOneWay && {
									...parsedLegs[0],
									departureAirport: selectedDestination?.properties,
									destinationAirport: selectedDeparture?.properties,
									departureAt: parsedLegs[0].returnAt,
								}
							}
							flightTypeName={formValues.trip.flightType.label}
							numberOfSeats={formValues.trip.seats}
							onEditRequestClick={editRequest}
							isOneWay={isOneWay}
							isRoundTrip={isRoundTrip}
						/>
					) : (
						<TripInfoCardMobile
							firstLeg={{
								...parsedLegs[0],
								departureAirport: selectedDeparture?.properties,
								destinationAirport: selectedDestination?.properties,
							}}
							returnLeg={
								!isOneWay && {
									...parsedLegs[0],
									departureAirport: selectedDestination?.properties,
									destinationAirport: selectedDeparture?.properties,
									departureAt: parsedLegs[0].returnAt,
								}
							}
							numberOfSeats={formValues.trip.seats}
							onEditRequestClick={editRequest}
							isOneWay={isOneWay}
							isRoundTrip={isRoundTrip}
							inverted
							detailed
							showMorePlaceInfo
						/>
					)
				) : (
					<Container id="raf-form" data-testid="raf-form">
						<GradientStripe />
						<FormContainer formContext={formContext} onSuccess={handleRAFSubmit}>
							<TripFormGroup flightTypesOptions={defaultFlightTypeOptions} isLoading={isLoading} />
						</FormContainer>
					</Container>
				)}
			</ScrollElement>

			<ScrollElement nameElement="raf-map">
				<Collapse
					in={showMap && !!departureCenter && !!destinationCenter}
					timeout={500}
					unmountOnExit
				>
					<Box id="raf-map" data-testid="raf-map" mt={6}>
						<AirportMap
							departure={{ lat: departureCenter?.[1], lng: departureCenter?.[0] }}
							destination={{ lat: destinationCenter?.[1], lng: destinationCenter?.[0] }}
							onSubmit={handleFormSubmit}
							selectedDestination={selectedDestination}
							selectedDeparture={selectedDeparture}
							selectDestination={selectDestination}
							selectDeparture={selectDeparture}
							closeMap={editRequest}
						/>
					</Box>
				</Collapse>
			</ScrollElement>
		</>
	);
};
