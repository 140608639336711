export const educationalBannerData = [
	{
		id: 1,
		title: 'Get real-time charter flight pricing',
		image: '/assets/educational_image_1.webp',
		list: [
			{
				content: (
					<>
						Get your <strong>trip price immediately</strong>
					</>
				),
				isNew: false,
			},

			{
				content: (
					<>
						The prices you see on Fllite are <strong>final</strong>
					</>
				),
				isNew: false,
			},
			{
				content: (
					<>
						Say <strong>goodbye to hidden fees</strong> and surprises
					</>
				),
				isNew: false,
			},
		],
	},
	{
		id: 2,
		title: 'Grab a seat in already booked flight',
		image: '/assets/educational_image_3.webp',
		list: [
			{
				content: (
					<>
						Save money by <strong>splitting costs</strong> with others
					</>
				),
				isNew: false,
			},

			{
				content: (
					<>
						Join <strong>Shared trip</strong> and pay only for your seats
					</>
				),
				isNew: false,
			},
			{
				content: (
					<>
						<strong>Propose</strong> your own Trip, wait for others to join and book when it fits
						your budget!
					</>
				),
				isNew: true,
			},
		],
	},
];

export const LOADER_TEXTS = [
	{ time: 5, content: 'Preparing your account' },
	{ time: 7, content: 'Looking for available aircraft' },
	{ time: 5, content: 'Calculating prices' },
	{ time: 3, content: 'Preparing your results' },
];
