import { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { DashboardBanner } from 'components/PageIndex/DashboardBanner';
import { useRouter } from 'next/router';

import { useAuthentication } from '@fllite-fe/shared';
import { RequestAFlightApp } from '@fllite-fe/shared/src/components/RequestAFlightApp';

import { JumpOnAFlight } from './JumpOnAFlight/JumpOnAFlight';
import { UpcomingTrip } from './UpcomingSection/UpcomingTrip';

const Container = styled('div')`
	width: 100vw;
	padding: 0 20px;

	${({ theme }) => theme.breakpoints.down('md')} {
		width: 100%;
	}
`;

const Heading = styled('div')`
	display: flex;
	max-width: ${({ theme }) => theme.dimensions.clientPageWidth};
	margin: auto;
	flex-direction: column;
	position: relative;
`;

const Title = styled('span')<{ bolder?: boolean }>`
	font-weight: ${({ bolder }) => (bolder ? 700 : 500)};
	font-size: 36px;
	line-height: 36px;
	color: white;

	${({ theme }) => theme.breakpoints.down('sm')} {
		font-weight: ${({ bolder }) => (bolder ? 700 : 400)};
		font-size: 32px;
		line-height: 150%;
		text-align: center;
	}
`;

const Welcome = styled('div')`
	margin: auto;
	padding: 85px 0 55px 0;
	max-width: ${({ theme }) => theme.dimensions.clientPageWidth};

	${({ theme }) => theme.breakpoints.down('sm')} {
		padding: 32px 0 50px 0;
	}
`;

const Cloud = styled('div')`
	position: absolute;
	width: 777px;
	height: 319px;
	background: url('/assets/cloud2.webp');
	background-repeat: no-repeat;
`;

export const Cloud1 = styled(Cloud)`
	top: -100px;
	left: -40%;
`;

export const Cloud2 = styled(Cloud)`
	top: -160px;
	left: 74.5%;
`;

const howToBannerInfo = {
	textContent: {
		title: 'New to private aviation? We make it simple for you.',
		description:
			"Check our how-to's to understand better what to expect before booking your private flight.",
	},
	imageContent: {
		url: '/assets/dashboard/how-to-banner.webp',
		alt: 'how-to banner',
	},
	buttonContent: {
		linkTo: '/articles',
		outlineFilled: true,
		buttonText: 'Go to How to Use Fllite',
		medium: true,
		testId: 'how-to-banner-button',
	},
};

export const HeroRequestAFlight = () => {
	const { query } = useRouter();
	const { user } = useAuthentication();
	const [hideJumpOnAFlightSection, setHideJumpOnAFlightSection] = useState(false);

	useEffect(() => {
		if (query?.scroll) {
			const rafForm = document.getElementById('raf-form');

			if (rafForm)
				window.scrollTo({
					top: rafForm.offsetTop,
					behavior: 'smooth',
				});
		}
	}, [query]);

	return (
		<Container>
			<Welcome>
				<Title>
					Welcome Aboard, <Title bolder>{user?.firstName}</Title>
				</Title>
			</Welcome>
			<Heading>
				<UpcomingTrip />

				<RequestAFlightApp
					onExtendedInfoClose={() => setHideJumpOnAFlightSection(false)}
					onExtendedInfoOpen={() => setHideJumpOnAFlightSection(true)}
				/>

				<Box>
					<JumpOnAFlight hideJumpOnAFlightSection={hideJumpOnAFlightSection} />
				</Box>

				<DashboardBanner bannerInfo={howToBannerInfo} />
			</Heading>
		</Container>
	);
};
