import { FC } from 'react';

interface DisplayFormProps {
	children: React.ReactNode;
	isVisible: boolean;
}

export const DisplayForm: FC<DisplayFormProps> = ({ children, isVisible }) => {
	if (!isVisible) return null;

	return <>{children}</>;
};
