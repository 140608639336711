export const AIRCRAFT_TYPES = [
	{
		id: 1,
		name: 'Jet',
		infoList: [
			'Best option for frequent travellers',
			'Speed around 360 - 600 mph',
			'Usually has Lavatory and Wi-Fi',
			'Slightly quieter than Turboprop',
		],
		image: '/assets/jet_with_background.webp',
		maximumSeats: 18,
	},
	{
		id: 2,
		name: 'Turboprop',
		infoList: [
			'Affordable option with the perks of a Jet',
			'Speed around 300 - 420 mph',
			'Often has Lavatory and Wi-Fi',
			'Jet engines powered by a propeller',
		],
		image: '/assets/turboprop_with_background.webp',
		maximumSeats: 9,
	},
	{
		id: 3,
		name: 'Piston',
		infoList: [
			'Most affordable option',
			'Speed around 170 - 245 mph',
			'Up to 4 seats',
			'The least quiet of the aircraft',
		],
		image: '/assets/piston_with_background.webp',
		maximumSeats: 6,
	},
];
