import React, { useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { useDebounce } from '../../../hooks/useDebounce';
import { useMapBoxSearchBoxSuggest } from '../../../hooks/useMapBoxSearchBox';
import { AutocompleteType } from '../../../mui-form-fields/AutocompleteElement';
import { ANYWHERE } from '../../../mui-form-fields/AutocompleteElement/MapBoxMenuOption';
import {
	AirportSelectMapBoxSharedAutocomplete,
	AirportSelectProps,
} from './AirportSelectMapBoxSharedAutocomplete';

export const AirportSelectMapBoxSearchBox = ({
	name,
	withAnywhere,
	...props
}: AirportSelectProps) => {
	const [query, setQuery] = useState<string>(null);
	const debouncedQuery = useDebounce(query, 400);

	const { data, isLoading } = useMapBoxSearchBoxSuggest({ query });
	const onChange = (search: string) => {
		setQuery(search);
	};

	const { watch } = useFormContext();
	const address = watch(name);

	const options = useMemo(
		() =>
			data?.length === 1 && data[0].mapbox_id === address?.value?.mapbox_id
				? []
				: (data ?? []).map((item) => ({
						label: item.name,
						value: item,
					})),
		[JSON.stringify(data)],
	);

	return (
		<AirportSelectMapBoxSharedAutocomplete
			name={name}
			debouncedQuery={debouncedQuery}
			address={address}
			options={
				withAnywhere
					? [
							...options,
							{
								label: ANYWHERE,
								value: {
									name: ANYWHERE,
								},
							},
						]
					: options
			}
			onChange={onChange}
			isLoading={isLoading}
			query={query}
			data={data}
			type={AutocompleteType.MapBoxSearch}
			{...props}
		/>
	);
};
