import { FC } from 'react';

import { NoEstimationsInfo } from './NoEstimationsInfo';
import { ReadyToFlyInfo } from './ReadyToFlyInfo';

interface InfoTextProps {
	allEstimationsAreDisabled: boolean;
}

export const InfoText: FC<InfoTextProps> = ({ allEstimationsAreDisabled }) =>
	allEstimationsAreDisabled ? <NoEstimationsInfo /> : <ReadyToFlyInfo />;
