import styled from '@emotion/styled';

import { Button } from '../Button';
import { Text } from '../Text';

const getRadialGradient = (
	position: number,
) => `radial-gradient(circle at 100% ${position}px, rgba(204, 0, 0, 0) 8px, white 9px),
radial-gradient(circle at 0 ${position}px, rgba(204, 0, 0, 0) 8px, white 9px)`;

const radialGradient = getRadialGradient(60);

export const Container = styled('div')`
	position: relative;
	z-index: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	border-radius: ${({ theme }) => theme.radius.boxRadius};
	padding: 24px 26px 60px;
	background: white;
	background: ${radialGradient};
	background-position: top right, top left;
	background-size: 51% 100%;
	background-repeat: no-repeat;

	${({ theme }) => theme.breakpoints.down('sm')} {
		background: white;
		background: ${radialGradient};
		background-position: top right, top left;
		background-size: 51% 100%;
		background-repeat: no-repeat;
		padding: 24px 26px 60px;
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		& p.Mui-error, & span.Mui-error {
			margin-bottom: -23px;
		}
		& span.Mui-error {
			// for long error messages we need have it on line
			position: absolute;
			white-space: nowrap;
		}
`;

export const GradientStripe = styled('div')`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 5px;
	background: ${({ theme }) => theme.gradients.secondary};
	margin-bottom: 18px;
	border-top-left-radius: ${({ theme }) => theme.radius.boxRadius};
	border-top-right-radius: ${({ theme }) => theme.radius.boxRadius};
`;

export const FirstLineBox = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 36px;

	${({ theme }) => theme.breakpoints.down('sm')} {
		flex-direction: column;
	}
`;

export const SecondLineBox = styled('div')`
	display: grid;
	grid-template-columns: auto 122px;
	max-width: ${({ theme }) => theme.dimensions.pageWidth};
	grid-column-gap: 8px;

	${({ theme }) => theme.breakpoints.down('sm')} {
		grid-template-columns: 1fr;
	}
`;

export const SelectAircraftBox = styled('div')`
	display: flex;
	justify-content: end;
	margin-top: 0px;
	flex-shrink: 0;
	min-width: 189px;

	${({ theme }) => theme.breakpoints.down('sm')} {
		margin-top: 27px;
		width: fit-content;
	}
`;

export const PlaceAndTimeBox = styled('div')<{ hasMargin?: boolean }>`
	display: grid;
	grid-template-columns: 434px auto;
	grid-column-gap: 8px;
	margin-bottom: ${({ hasMargin }) => (hasMargin ? '10px' : 0)};

	${({ theme }) => theme.breakpoints.down('md')} {
		grid-template-columns: 1fr 1fr;
	}

	${({ theme }) => theme.breakpoints.down('sm')} {
		grid-template-columns: 1fr;
	}
`;

export const Column = styled('div')`
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: space-between;
`;

export const Title = styled('div')`
	display: flex;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 10px 32px;

	${({ theme }) => theme.breakpoints.down('sm')} {
		display: none;
	}
`;

export const FirstCol = styled('div')`
	position: relative;
	margin-top: 0;
	padding: 0 10px;
	width: 100%;

	@media (min-width: 767px) {
		width: 50%;
	}
`;

export const SecondCol = styled('div')`
	position: relative;
	padding: 0 10px;
	margin-top: 45px;
	width: 100%;

	@media (min-width: 767px) {
		width: 50%;
		margin-left: 20px;
		margin-top: 0;
	}
`;

export const DatePickerColumn = styled('div')`
	width: 100%;
	position: relative;
	padding-left: 12px;
	padding-right: 45px;
	margin-left: 20px;

	${({ theme }) => theme.breakpoints.down('sm')} {
		margin-left: 12px;
		margin-top: 40px;
		padding-right: 25px;
	}
`;

export const StyledForm = styled('form')`
	width: 100%;
`;

export const FromToBox = styled('div')`
	display: flex;
	align-items: flex-start;
	padding-left: 15px;
	padding-top: 30px;
	flex-direction: column;
	margin-top: 4px;
	@media (min-width: 767px) {
		flex-direction: row;
	}
`;

export const SubmitButton = styled(Button)`
	width: 80px;
	min-width: 80px;
	height: 64px;
	border-radius: ${({ theme }) => theme.radius.smallBoxRadius};
	position: absolute;
	bottom: -32px;
	right: -16px;
	z-index: 2;

	${({ theme }) => theme.breakpoints.down('sm')} {
		right: -8px;
	}
`;

export const IconBox = styled('div')`
	position: absolute;
	top: 6px;
	left: -15px;
`;

export const PassengerBox = styled('div')`
	min-width: 132px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	${({ theme }) => theme.breakpoints.down('sm')} {
		margin-top: 30px;
	}
`;

export const InnerBox = styled('div')`
	width: 92px;
	position: relative;
`;

export const InnerBoxInput = styled('div')`
	width: 92px;
	text-align: center;
`;

export const PersonIconWrapper = styled('div')`
	margin-right: 8px;
	margin-top: 8px;
	width: 16px;

	svg {
		width: 16px;
	}
`;

export const TitleBox = styled('div')`
	display: flex;
	align-items: center;
	position: absolute;
	left: 0;
	top: 3.6rem;
	z-index: 10;
	${({ theme }) => theme.breakpoints.up('md')} {
		top: 55%;
	}
`;

export const StyledText = styled(Text)`
	letter-spacing: 1px;
`;

export const InfoIconWrapper = styled('div')`
	margin-left: 4px;
	width: 16px;

	svg {
		width: 100%;
	}
`;
