import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MapboxStyles = styled(Box)`
	& .mapboxgl-ctrl-group {
		box-shadow: none;
		border: 1px solid #0056be;
		border-radius: 8px;
		padding: 0 2px;
	}

	& .mapboxgl-ctrl-group button {
		width: 36px;
		height: 40px;
	}

	& .mapboxgl-ctrl-icon {
		filter: invert(20%) sepia(67%) saturate(3080%) hue-rotate(202deg) brightness(98%) contrast(50%); // hack to change color of svg icon in background
	}

	& .mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
		background-size: 30px;
	}

	& .mapboxgl-popup {
		margin-top: 20px;

		${({ theme }) => theme.breakpoints.up('sm')} {
			margin-top: -10px;
		}
	}

	& .mapboxgl-popup-close-button {
		font-size: 20px;
	}

	& .mapboxgl-popup-tip {
		border-top-color: #e8f2ff;
		border-bottom-color: #e8f2ff;
	}

	& .mapboxgl-popup-content {
		background: #e8f2ff;
	}
`;
