import distance from '@turf/distance';
import { point } from '@turf/helpers';

export const getDistance = (depLat, depLng, destLat, destLng) => {
	if (!depLat || !depLng || !destLat || !destLng) {
		return undefined;
	}
	const departure = point([depLng, depLat]);
	const destination = point([destLng, destLat]);
	const distanceFromPoint = distance(departure, destination, { units: 'meters' });

	return distanceFromPoint;
};
