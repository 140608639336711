import distance from '@turf/distance';
import { point } from '@turf/helpers';

export const isCenterInRadius = (latitude, longitude, positionLat, positionLng, radius) => {
	const centerPoint = point([longitude, latitude]);
	const positionPoint = point([positionLng, positionLat]);
	const distanceFromPoint = distance(centerPoint, positionPoint, { units: 'miles' });

	return distanceFromPoint <= radius;
};
