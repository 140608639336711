import {
	SearchBoxFeatureSuggestion,
	SearchBoxSuggestion,
	SessionToken,
} from '@mapbox/search-js-core/dist/index';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { getContextForApp } from '../utils/config';
import { useDebounce } from './useDebounce';

const ACCESS_TOKEN = getContextForApp().mapBoxAccessToken;
const MAPBOX_BASE_SEARCH_URL = 'https://api.mapbox.com/search/searchbox/v1';
const SUGGEST_BASE_URL = `${MAPBOX_BASE_SEARCH_URL}/suggest`;
const RETRIEVE_BASE_URL = `${MAPBOX_BASE_SEARCH_URL}/retrieve`;

const SUGGESTION_COUNT_CALL_NAME = 'mapbox-suggestion-call-count';
const SUGGESTION_CALL_COUNT_LIMIT = 49; // official is 50, but we want to be safe
const RETRIEVE_COUNT_CALL_NAME = 'mapbox-retrieve-call-count';
const RETRIEVE_CALL_COUNT_LIMIT = 1;
const MAPBOX_SESSION_TOKEN_COOKIE_NAME = 'mapbox-session-token';

const cookies = new Cookies();

const getNewSessionToken = (): string => {
	const token = new SessionToken().id;
	cookies.set(MAPBOX_SESSION_TOKEN_COOKIE_NAME, token, {
		expires: new Date(new Date().getTime() + 2 * 60 * 1000), // 2 minutes from now
	});
	return token;
};

enum TokenCallType {
	SUGGESTION,
	RETRIEVE,
}

const invalidedTokenStorage = () => {
	cookies.remove(MAPBOX_SESSION_TOKEN_COOKIE_NAME);
	cookies.remove(SUGGESTION_COUNT_CALL_NAME);
	cookies.remove(RETRIEVE_COUNT_CALL_NAME);
};

const isTokenStillValid = (tokenCallType: TokenCallType): boolean => {
	const token = cookies.get(MAPBOX_SESSION_TOKEN_COOKIE_NAME);
	if (!token) return false;

	if (tokenCallType === TokenCallType.SUGGESTION) {
		const count = parseInt(cookies.get(SUGGESTION_COUNT_CALL_NAME), 10) || 0;
		return count < SUGGESTION_CALL_COUNT_LIMIT;
	} else {
		const count = parseInt(cookies.get(RETRIEVE_COUNT_CALL_NAME), 10) || 0;
		return count < RETRIEVE_CALL_COUNT_LIMIT;
	}
};

const getToken = (tokenCallType: TokenCallType): string => {
	const tokenIsStillValid = isTokenStillValid(tokenCallType);
	if (!tokenIsStillValid) {
		invalidedTokenStorage();
	}

	return cookies.get(MAPBOX_SESSION_TOKEN_COOKIE_NAME) || getNewSessionToken();
};

const updateSuggestionsCallStorage = () => {
	const count = parseInt(cookies.get(SUGGESTION_COUNT_CALL_NAME), 10) || 0;
	cookies.set(SUGGESTION_COUNT_CALL_NAME, count + 1);
};

const updateRetrieveCallStorage = () => {
	const count = parseInt(cookies.get(RETRIEVE_COUNT_CALL_NAME), 10) || 0;
	cookies.set(RETRIEVE_COUNT_CALL_NAME, count + 1);
};

const suggestFetcher = async ({ query }) => {
	if (!query) return { data: { features: [] } };
	const token = getToken(TokenCallType.SUGGESTION);
	updateSuggestionsCallStorage();
	return axios.get(
		`${SUGGEST_BASE_URL}?q=${query}&access_token=${ACCESS_TOKEN}&session_token=${token}&language=en&country=us,mx,ca&poi_category=airport&types=poi,place`,
	);
};

export const retrieveFetcher = async ({ mapbox_id }) => {
	if (!mapbox_id) return { data: {} };
	const token = getToken(TokenCallType.RETRIEVE);
	updateRetrieveCallStorage();
	return axios.get(
		`${RETRIEVE_BASE_URL}/${mapbox_id}?access_token=${ACCESS_TOKEN}&session_token=${token}`,
	);
};

interface MapBoxSearchBoxSuggestionProps {
	query: string;
}
export const useMapBoxSearchBoxSuggest = ({ query }: MapBoxSearchBoxSuggestionProps) => {
	const debouncedQuery = useDebounce(query, 500);

	const response = useMapBoxSearchBoxSuggestionQuery({ query: debouncedQuery });

	return { ...response };
};

const useMapBoxSearchBoxSuggestionQuery = ({ query }: MapBoxSearchBoxSuggestionProps) => {
	const response = useQuery({
		queryKey: ['mapboxSuggestion', query],
		queryFn: () => suggestFetcher({ query }),
		enabled: !!query,
		retry: false,
	});

	const data = (response.data?.data?.suggestions || []).sort((a, b) => {
		const aIsAirport = a.poi_category?.includes('airport');
		const bIsAirport = b.poi_category?.includes('airport');
		return aIsAirport && !bIsAirport ? -1 : !aIsAirport && bIsAirport ? 1 : 0;
	}) as SearchBoxSuggestion[];

	return { ...response, data };
};

export const useMapBoxSearchBoxRetrieve = ({ mapbox_id }: { mapbox_id: string }) => {
	const response = useQuery({
		queryKey: ['mapboxRetrieve', mapbox_id],
		queryFn: () => retrieveFetcher({ mapbox_id }),
		enabled: !!mapbox_id,
		retry: false,
	});
	const data = response.data?.data?.features?.[0] as SearchBoxFeatureSuggestion;
	return { ...response, data };
};
