import type { CircleLayer, LayerProps, SymbolLayer } from 'react-map-gl';

const CIRCLE_RADIUS_FOR_POINT: CircleLayer['paint']['circle-radius'] = [
	'interpolate',
	['linear'],
	['zoom'],
	0,
	['match', ['get', 'airport_category'], 'local', 3, 'national', 16, 18],
	9,
	['match', ['get', 'airport_category'], 'local', 4, 'national', 16, 18],
	10,
	['match', ['get', 'airport_category'], 'local', 4, 'national', 23, 30],
	12,
	['match', ['get', 'airport_category'], 'local', 13, 'national', 23, 30],
];

const TEXT_SIZE_FOR_POINT: SymbolLayer['layout']['text-size'] = [
	'interpolate',
	['linear'],
	['zoom'],

	0,
	['match', ['get', 'airport_category'], 'local', 0, 10],
	9,
	['match', ['get', 'airport_category'], 'local', 0, 11],

	10,
	['match', ['get', 'airport_category'], 'local', 0, 14],

	12,
	['match', ['get', 'airport_category'], 'local', 9, 14],
];

export const localPointLayer: LayerProps = {
	id: 'local-airports',
	type: 'circle',
	paint: {
		'circle-color': '#8CC0FF',
		'circle-radius': CIRCLE_RADIUS_FOR_POINT,
		'circle-stroke-width': 1,
		'circle-stroke-color': '#fff',
	},
};

export const localPointTextLayer: LayerProps = {
	id: 'local-airport-text',
	type: 'symbol',
	paint: {
		'text-color': '#fff',
	},

	layout: {
		'text-field': ['to-string', ['get', 'airportCode']],
		'text-font': ['Poppins SemiBold', 'Arial Unicode MS Regular'],
		'text-size': TEXT_SIZE_FOR_POINT,
	},
};

export const unclusteredPointLayer: LayerProps = {
	id: 'filtered-airports',
	type: 'circle',
	paint: {
		'circle-color': [
			'match',
			['get', 'airport_category'],
			'international',
			'#0056BE',
			'national',
			'#002553',
			'local',
			'#8CC0FF',
			'#8CC0FF',
		],
		'circle-radius': CIRCLE_RADIUS_FOR_POINT,
		'circle-stroke-width': 1,
		'circle-stroke-color': '#fff',
	},
};

export const unclusteredPointTextLayer: LayerProps = {
	id: 'airport-text',
	type: 'symbol',
	paint: {
		'text-color': '#fff',
	},

	layout: {
		'text-field': ['to-string', ['get', 'airportCode']],
		'text-font': ['Poppins SemiBold', 'Arial Unicode MS Regular'],
		'text-size': TEXT_SIZE_FOR_POINT,
	},
};

export const clusterLayer: LayerProps = {
	id: 'clusters',
	type: 'circle',
	filter: ['has', 'point_count'],
	paint: {
		'circle-color': '#0056BE',
		'circle-radius': 30,
		'circle-stroke-width': 1,
		'circle-stroke-color': '#fff',
	},
};

export const clusterCountLayer: LayerProps = {
	id: 'cluster-count',
	type: 'symbol',
	filter: ['has', 'point_count'],
	paint: {
		'text-color': 'hsl(0, 0%, 100%)',
	},
	layout: {
		'text-field': '{point_count_abbreviated}',
		'text-font': ['Poppins SemiBold', 'Arial Unicode MS Regular'],
		'text-size': 12,
		'text-allow-overlap': true,
	},
};

export const highlightedPointLayer: LayerProps = {
	id: 'highlighted-airports',
	type: 'circle',
	paint: {
		'circle-color': '#FF9200',
		'circle-radius': CIRCLE_RADIUS_FOR_POINT,
		'circle-stroke-width': 1,
		'circle-stroke-color': '#fff',
	},
};

export const highlightedPointTextLayer: LayerProps = {
	id: 'highlighted-airports-text',
	type: 'symbol',
	paint: {
		'text-color': '#353535',
	},
	layout: {
		'text-field': ['to-string', ['get', 'airportCode']],
		'text-font': ['Poppins SemiBold', 'Arial Unicode MS Regular'],
		'text-size': TEXT_SIZE_FOR_POINT,
	},
};

export const routeLayer: LayerProps = {
	id: 'route',
	type: 'line',
	paint: {
		'line-width': 4,
		'line-color': '#FF9200',
	},
};
