import { Estimation } from '../types';
import { formatAirportName } from './formatters';

export const transformEstimationToRaf = (values: Estimation) => {
	if (!values) {
		return {};
	}
	const firstLeg = values.estimationLegs[0];

	if (!firstLeg) {
		return null;
	}

	const departure = {
		value: firstLeg.departureAirport,
		label: formatAirportName(firstLeg.departureAirport),
	};
	const destination = {
		value: firstLeg.destinationAirport,
		label: formatAirportName(firstLeg.destinationAirport),
	};
	const legs = {
		multiLeg: values.estimationLegs.map((item) => ({
			departure: {
				value: item.departureAirport,
				label: formatAirportName(item.departureAirport),
			},
			dateFrom: item.departureAt,
			destination: {
				value: item.destinationAirport,
				label: formatAirportName(item.destinationAirport),
			},
		})),

		oneWay: {
			departure: departure,
			dateFrom: firstLeg.departureAt,
			destination: destination,
		},
		roundTrip: {
			departure: departure,
			destination: {
				value: firstLeg.destinationAirport,
				label: formatAirportName(firstLeg.destinationAirport),
			},
			dateFrom: firstLeg.departureAt,
			dateTo: values.estimationLegs[1]?.departureAt,
		},
	};

	return {
		trip: {
			flightType: {
				label: (values.flightType as any).name,
				value: (values.flightType as any).id,
				constantKey: (values.flightType as any).constantKey,
			},
			seats: values.numberOfSeats,
			aircraftType: (values.aircraftDetailedTypes as any).map((a) => a.id?.toString()),
			legs,
		},
	};
};
