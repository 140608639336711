import React, { FC } from 'react';

import styled from '@emotion/styled';
import { FlightLand, FlightTakeoff } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

const ChipBox = styled(Box)<{ active: boolean }>`
	padding: 4px;
	cursor: pointer;
	border-radius: 8px;
	border: 1px solid #dddfe3;
	background: ${({ active }) =>
		active ? 'linear-gradient(135deg, #2077E0 0%, #004BA6 100%)' : '#E8F2FF'};
	transition: background 0.2s ease-in-out;
	${({ theme }) => theme.breakpoints.up('mini')} {
		padding: 4px 16px;
	}

	${({ theme }) => theme.breakpoints.down('md')} {
		width: 100%;
	}
`;

interface Props {
	label: string;
	active: boolean;
	onClick: () => void;
	isDestination?: boolean;
}
export const AirportCodeChip: FC<Props> = ({ label, active, onClick, isDestination }) => {
	return (
		<ChipBox active={active} onClick={onClick} data-testid="airport-chip">
			<Stack direction="row" spacing={2} alignItems="center" color={active ? 'white' : 'primary'}>
				{isDestination ? (
					<FlightLand color="inherit" fontSize="small" />
				) : (
					<FlightTakeoff color="inherit" fontSize="small" />
				)}

				<Typography color={active ? 'white' : 'primary'} fontWeight={500} fontSize={20}>
					{label}
				</Typography>
			</Stack>
		</ChipBox>
	);
};
