import { FC } from 'react';

import styled from '@emotion/styled';
import { Box, Chip, Stack, Typography } from '@mui/material';
import Image from 'next/image';

import { Icon } from '../Icon';
import { educationalBannerData } from './educationalBannerData';

const Banner = styled(Box)<{ noMargin?: boolean }>`
	background: linear-gradient(0deg, rgba(140, 192, 255, 0.2), rgba(140, 192, 255, 0.2)), #ffffff;
	width: 952px;
	max-width: 100%;
	margin: ${({ noMargin }) => (noMargin ? '0 auto' : '20px auto 20px')};
	border-radius: 8px;
	box-shadow: 0px 4px 20px rgba(53, 53, 53, 0.12);
	padding: 5px;
	text-align: left;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin: ${({ noMargin }) => (noMargin ? '0 auto' : '90px auto 20px')};
	}
`;

const StyledImage = styled(Image)`
	border-radius: 8px;
`;

interface EducationalBannerProps {
	content: (typeof educationalBannerData)[0];
	noMargin?: boolean;
}

export const EducationalBanner: FC<EducationalBannerProps> = ({ content, noMargin }) => {
	return (
		<Banner noMargin={noMargin}>
			<Stack
				direction={{ xs: 'column', md: 'row' }}
				spacing={{ xs: 1, sm: 2 }}
				alignItems={{ xs: 'flex-start', md: 'center' }}
			>
				<Box
					borderRadius="8px"
					width={{ xs: '100%', md: '430px' }}
					height={{ xs: '120px', sm: '250px' }}
					flexShrink={0}
					position="relative"
					alignSelf={{ xs: 'center', md: 'auto' }}
					maxWidth="100%"
					overflow="hidden"
				>
					<StyledImage src={content.image} alt={content.title} fill objectFit="cover" />
				</Box>
				<Box py={{ xs: '10px', md: '22px' }} px="20px" width="100%">
					<Typography color="primary" fontSize={20} fontWeight={600} mb={2}>
						{content.title}
					</Typography>
					{content.list.map((item, index) => (
						<Stack
							key={index}
							direction={{ xs: 'column', sm: 'row' }}
							spacing={1}
							mb={index === content.list.length - 1 ? 0 : 2}
							alignItems={{ xs: 'flex-start', md: 'center' }}
						>
							<Stack direction="row" spacing={1}>
								<Box pt="2px">
									<Icon type="aircraft-green" flex />
								</Box>
								<Typography fontSize={16}>{item.content}</Typography>
								{item.isNew && (
									<Chip variant="successBold" label="New!" sx={{ fontSize: '10px' }} />
								)}
							</Stack>
						</Stack>
					))}
				</Box>
			</Stack>
		</Banner>
	);
};
