import { CheckCircle, Flag } from '@mui/icons-material';

export const popupContent = [
	{
		airport_category: 'international',
		name: 'International Airport',
		description: (
			<>
				International Airports have higher fees, which makes your trip more expensive. <br /> <br />
				We recommend selecting a <strong>National Airport (dark blue circles)</strong>.
			</>
		),
		icon: <Flag color="primary" sx={{ fontSize: '14px' }} />,
	},
	{
		airport_category: 'national',
		name: 'National Airport',
		description: <>Lower fees, which makes your trip cheaper.</>,
		icon: <CheckCircle color="primary" sx={{ fontSize: '14px' }} />,
	},
];
