import styled from '@emotion/styled';
import Image from 'next/image';

export const BannerContainer = styled('div')`
	background: linear-gradient(0deg, rgba(140, 192, 255, 0.2), rgba(140, 192, 255, 0.2)), #ffffff;
	box-shadow: 0px 4px 20px rgba(53, 53, 53, 0.12);
	border-radius: 8px;
	width: 100%;
	padding: 4px;
`;

export const StyledImage = styled(Image)`
	object-fit: contain;
`;
