import { FC, useMemo } from 'react';

import styled from '@emotion/styled';
import { Done, SellOutlined, SpeedOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

const Content = styled(Box)`
	border-radius: 40px;
	position: absolute;
	right: 15px;
	top: -20px;
	padding: 4px 12px;
`;
interface Props {
	aircraftType: string;
}

export const CustomChip: FC<Props> = ({ aircraftType }) => {
	const chipContent = useMemo(() => {
		switch (true) {
			case aircraftType === 'Jet':
				return {
					color: '#0056BE',
					label: 'Fastest option',
					icon: <SpeedOutlined fontSize="small" color="inherit" />,
					background:
						'linear-gradient(0deg, rgba(140, 192, 255, 0.15), rgba(140, 192, 255, 0.15)), #FFFFFF',
				};
			case aircraftType === 'Turboprop':
				return {
					color: '#FF9200',
					label: 'Best choice',
					icon: <Done fontSize="small" color="inherit" />,
					background:
						'linear-gradient(0deg, rgba(255, 146, 0, 0.15), rgba(255, 146, 0, 0.15)), #FFFFFF',
				};
			default:
				return {
					color: '#53AE0E',
					label: 'Most affordable',
					icon: <SellOutlined fontSize="small" color="inherit" />,
					background:
						'linear-gradient(0deg, rgba(83, 174, 14, 0.15), rgba(83, 174, 14, 0.15)), #FFFFFF',
				};
		}
	}, [aircraftType]);
	return (
		<Content
			color={chipContent.color}
			border={`1px solid ${chipContent.color}`}
			sx={{ background: chipContent.background }}
		>
			<Stack spacing={1} justifyContent="space-between" direction="row" alignItems="center">
				{chipContent.icon}
				<Typography color="inherit" fontWeight={600} fontSize={12} textTransform="uppercase">
					{chipContent.label}
				</Typography>
			</Stack>
		</Content>
	);
};
