import { RafFormValues } from '../../../hooks/useAccountContext';
import { Airport } from '../../../types';
import { FormValues } from '../RaF';
import { getLegs } from './getLegs';

export const parseFormValues = (
	formValues: FormValues,
	departureAirport: Airport,
	destinationAirport: Airport,
) => {
	const legs = getLegs(formValues);

	const parsedValues: RafFormValues = {
		flightType: formValues.trip.flightType,
		numberOfSeats: { passengers: formValues.trip.seats },
		legs: legs.map((leg) => ({
			...leg,
			departureAirport: { value: departureAirport },
			destinationAirport: { value: destinationAirport },
		})),
		calculationOnly: formValues.calculationOnly === 'true',
		calculationInquiry: formValues.calculationInquiry,
	};

	return parsedValues;
};
